import images from 'assets/images';

export const artistInfo1 = [
	{
		id: 1,
		profile: images.RookieNineI,
		name: {
			en: 'NINE.i',
			ko: '나인아이'
		},
		website: [
			{ type: 'official', url: 'https://www.firstone-ent.com/nine-i' },
			{
				type: 'twitter',
				url: 'https://twitter.com/nine_i_firstone/status/1648220837798117376?s=46&t=MVBQAhimhYqdOVtndtfbjg'
			},
			{ type: 'youtube', url: 'https://www.youtube.com/NINEiOFFICIAL' },
			{ type: 'tiktok', url: 'https://www.tiktok.com/@nine.i_official' },
			{ type: 'instagram', url: 'https://www.instagram.com/nine.i_official/' }
		],
		prize: '#2위수상자'
	},
	{
		id: 2,
		profile: images.RookieLapillus,
		name: {
			en: 'Lapillus',
			ko: '라필루스'
		},
		website: [
			{
				type: 'official',
				url: 'https://www.mldenter.com/%EB%B3%B5%EC%A0%9C-tfn-main-1'
			},
			{ type: 'twitter', url: 'https://twitter.com/offclLapillus' },
			{ type: 'youtube', url: 'https://www.youtube.com/@Lapillus_official' },
			{ type: 'tiktok', url: 'https://www.tiktok.com/@official_lapillus' },
			{ type: 'instagram', url: 'https://www.instagram.com/official.lapillus/' }
		],
		prize: '#1위수상자'
	},
	{
		id: 3,
		profile: images.RookieFiftyFifty,
		name: {
			en: 'FIFTY FIFTY',
			ko: '피프티피프티'
		},
		website: [
			{ type: 'official', url: 'https://www.theattrakt.com/artists' },
			{ type: 'twitter', url: 'https://www.twitter.com/we_fiftyfifty' },
			{ type: 'youtube', url: 'https://www.youtube.com/@we_fiftyfifty' },
			{ type: 'tiktok', url: 'https://www.tiktok.com/@we_fiftyfifty' },
			{ type: 'instagram', url: 'https://www.instagram.com/we_fiftyfifty' }
		]
	},
	{
		id: 4,
		profile: images.Rookie8Turn,
		name: {
			en: '8TURN',
			ko: '에잇턴'
		},
		website: [
			{
				type: 'official',
				url: 'http://mnhenter.com/board/view?bd_id=singer&wr_id=366'
			},
			{ type: 'twitter', url: 'https://twitter.com/8TURN_official' },
			{ type: 'youtube', url: 'https://youtu.be/W8T8kW3HSus' },
			{ type: 'tiktok', url: 'https://tiktok.com/@8turn_official' },
			{ type: 'instagram', url: 'https://instagram.com/8turn.official' }
		]
	},
	{
		id: 5,
		profile: images.RookieClassy,
		name: {
			en: 'CLASS:y',
			ko: '클라씨'
		},
		website: [
			{ type: 'official', url: 'https://m25ent.com/21' },
			{ type: 'twitter', url: 'https://twitter.com/M25_CLASSy' },
			{ type: 'youtube', url: 'https://www.youtube.com/@OFFICIAL_CLASSy' },
			{ type: 'instagram', url: 'https://instagram.com/m25_classy' }
		]
	}
];

export const artistInfo2 = [
	{
		id: 1,
		profile: images.RookieMirae,
		name: {
			en: 'MIRAE',
			ko: '미래소년'
		},
		website: [
			{
				type: 'official',
				url: 'http://www.dspmedia.co.kr/artist/profile?ca_id=70'
			},
			{ type: 'twitter', url: 'https://twitter.com/official_MIRAE?s=20' },
			{ type: 'youtube', url: 'https://www.youtube.com/@official_MIRAE' },
			{ type: 'tiktok', url: 'https://www.tiktok.com/@official_mirae' },
			{ type: 'instagram', url: 'https://www.instagram.com/official__mirae/' }
		],
		prize: '#2위수상자'
	},
	{
		id: 2,
		profile: images.RookieBillie,
		name: {
			en: 'Billlie',
			ko: '빌리'
		},
		website: [
			{
				type: 'official',
				url: 'http://www.mystic89.net/artist/billlie'
			},
			{ type: 'twitter', url: 'https://twitter.com/Billlieofficial' },
			{ type: 'youtube', url: 'https://www.youtube.com/@Billlie' },
			{ type: 'tiktok', url: 'https://www.tiktok.com/@billlie.official' },
			{ type: 'instagram', url: 'https://www.instagram.com/billlie.official/' }
		]
	},
	{
		id: 3,
		profile: images.RookieJustB,
		name: {
			en: 'JUST B',
			ko: '저스트비'
		},
		website: [
			{ type: 'official', url: 'https://bluedot-ent.com/justb/' },
			{ type: 'twitter', url: 'https://twitter.com/JUSTB_Official' },
			{ type: 'youtube', url: 'https://www.youtube.com/JUSTB_Official' },
			{ type: 'tiktok', url: 'https://www.tiktok.com/@justb_official' },
			{ type: 'instagram', url: 'https://www.instagram.com/JUSTB_IG_official/' }
		],
		prize: '#1위수상자'
	},
	{
		id: 4,
		profile: images.RookieTriBe,
		name: {
			en: 'TRI.BE',
			ko: '트라이비'
		},
		website: [
			{ type: 'official', url: 'https://tr-ent.co.kr/tri-be/' },
			{ type: 'twitter', url: 'https://twitter.com/tribedaloca' },
			{ type: 'youtube', url: 'https://www.youtube.com/TRIBE_2021' },
			{ type: 'tiktok', url: 'https://www.tiktok.com/@tribedaloca' },
			{ type: 'instagram', url: 'https://www.instagram.com/tribedaloca/' }
		]
	},
	{
		id: 5,
		profile: images.RookiePurpleKiss,
		name: {
			en: 'PURPLE KISS',
			ko: '퍼플키스'
		},
		website: [
			{ type: 'official', url: 'https://purplekiss.co.kr/' },
			{
				type: 'twitter',
				url: 'https://twitter.com/rbw_purplekiss/status/1646079401619234820?s=46&t=aUAzF9etyfzqCQoynUgUOg'
			},
			{ type: 'youtube', url: 'https://www.youtube.com/PURPLEKISS' },
			{ type: 'tiktok', url: 'https://www.tiktok.com/@rbw_purplekiss' },
			{
				type: 'instagram',
				url: 'https://www.instagram.com/reel/Cq7j3ulJ1hJ/?utm_source=ig_web_copy_link'
			}
		]
	},
	{
		id: 6,
		profile: images.RookiePixy,
		name: {
			en: 'PIXY',
			ko: '픽시'
		},
		website: [
			{ type: 'official', url: 'https://pixy.bstage.in/' },
			{
				type: 'twitter',
				url: 'https://twitter.com/official_pixy'
			},
			{
				type: 'youtube',
				url: 'https://www.youtube.com/PIXYOFFICIAL'
			},
			{
				type: 'tiktok',
				url: 'https://www.tiktok.com/@pixyofficial'
			},
			{
				type: 'instagram',
				url: 'https://www.instagram.com/pixy_official_/'
			}
		]
	},
	{
		id: 7,
		profile: images.RookieBlitzers,
		name: {
			en: 'BLITZERS',
			ko: '블리처스'
		},
		website: [
			{
				type: 'official',
				url: 'http://www.wuzo.co.kr/bbs/board.php?bo_table=artist&wr_id=2'
			},
			{
				type: 'twitter',
				url: 'https://twitter.com/WUZO_BLITZERS'
			},
			{
				type: 'youtube',
				url: 'https://www.youtube.com/@BLITZERSOFFICIAL'
			},
			{ type: 'tiktok', url: 'https://www.tiktok.com/@blitzers_official' },
			{
				type: 'instagram',
				url: 'https://www.instagram.com/official_blitzers/'
			}
		]
	},
	{
		id: 8,
		profile: images.RookieYeahShine,
		name: {
			en: 'YEAHSHINE',
			ko: '예빈나다'
		},
		website: [
			{
				type: 'twitter',
				url: 'https://twitter.com/YeahshineO'
			},
			{
				type: 'youtube',
				url: 'https://www.youtube.com/@YEAHSHINE'
			},
			{
				type: 'tiktok',
				url: 'https://www.tiktok.com/@yeahshine'
			},
			{
				type: 'instagram',
				url: 'https://www.instagram.com/yeahshine_/'
			}
		]
	}
];
