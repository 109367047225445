import {
	Box,
	ButtonBase,
	Container,
	Grid,
	Stack,
	styled,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

import images from 'assets/images';

import { ResponsiveImage } from 'components';

import { APP_FANCAST_VOTE_MAIN_URL } from 'constants/common';

import { VOTE_WAY } from 'views/sma/constants';

// ----------------------------------------------------------------------

const StyledVoteWayBox = styled(Box)(({ item, matchUpMd }) => ({
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: item.borderColor,
	backgroundColor: item.backgroundColor,
	...(matchUpMd && {
		width: '100%',
		paddingTop: '36px',
		paddingLeft: '36px',
		borderRadius: 24
	}),
	...(!matchUpMd && {
		width: 275,
		paddingTop: '24px',
		paddingLeft: '20px',
		borderRadius: 20,
		marginLeft: '16px'
	})
}));

const StyledDirectVoteButton = styled(ButtonBase)(({ theme, matchUpMd }) => ({
	backgroundColor: theme.palette.black[1],
	width: '100%',
	...(matchUpMd && {
		width: '100%',
		padding: '36px 36px 47px 36px',
		borderRadius: 24
	}),
	...(!matchUpMd && {
		padding: '20px',
		borderRadius: 20,
		display: 'block',
		marginTop: '12px',
		marginLeft: '48px',
		marginRight: '48px'
	})
}));

const StyledVoteWayWrapperMobile = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	flexWrap: 'wrap',
	marginTop: '20px',
	paddingLeft: '32px',
	paddingRight: '48px',
	overflow: 'scroll',
	height: '385px',
	'::-webkit-scrollbar': {
		display: 'none',
		msOverflowStyle: 'none'
	}
}));

// ----------------------------------------------------------------------

const FancastVoteWay = ({ item, matchUpMd }) => {
	if (matchUpMd) {
		return (
			<StyledVoteWayBox item={item} matchUpMd={matchUpMd}>
				<Stack flexDirection={'row'}>
					<Typography variant={'h12'} fontWeight={500}>
						{item.id}
					</Typography>
					<Typography
						variant={'caption1'}
						fontWeight={500}
						ml={'30px'}
						whiteSpace={'pre-wrap'}
					>
						{item.text}
					</Typography>
				</Stack>
				{/* 창 사이즈 resize에 따른, 이미지 위치 고정을 위해 padding 간격 조정 */}
				<Grid
					container
					mt={item.id === 2 ? '8px' : '16px'}
					pl={item.imageSize.md.pl}
					pr={item.imageSize.md.pr}
				>
					<ResponsiveImage
						src={item.image.md}
						alt={`vote_way_${item.id}`}
						width={item.imageSize.md.width}
						height={item.imageSize.md.height}
					/>
				</Grid>
			</StyledVoteWayBox>
		);
	}

	return (
		<StyledVoteWayBox item={item} matchUpMd={matchUpMd}>
			<Stack flexDirection={'row'}>
				<Typography variant={'h12'} fontWeight={500}>
					{item.id}
				</Typography>
				<Typography
					variant={'caption3'}
					fontWeight={500}
					ml={'16px'}
					whiteSpace={'pre-wrap'}
				>
					{item.text}
				</Typography>
			</Stack>
			{/* 창 사이즈 resize에 따른, 이미지 위치 고정을 위해 padding 간격 조정 */}
			<Grid container mt={'20px'} pl={item.imageSize.sm.pl} pr={item.imageSize.sm.pr}>
				<ResponsiveImage
					src={item.image.sm}
					alt={`vote_way_${item.id}`}
					width={item.imageSize.sm.width}
					height={item.imageSize.sm.height}
				/>
			</Grid>
		</StyledVoteWayBox>
	);
};

const FancastDirectVoteButton = ({ theme, matchUpMd }) => {
	if (matchUpMd) {
		return (
			<StyledDirectVoteButton
				component={'div'}
				disableRipple
				theme={theme}
				matchUpMd={matchUpMd}
				onClick={() => window.open(APP_FANCAST_VOTE_MAIN_URL)}
			>
				<Stack flexDirection={'row'} flex={1} justifyContent={'space-between'}>
					<Stack>
						<Typography
							variant={'caption1'}
							fontWeight={500}
							color={theme.palette.white[1]}
						>
							지금 바로
							<br />
							투표하러 가기
						</Typography>
						<Typography
							variant={'caption5'}
							fontWeight={500}
							color={theme.palette.yellow[1]}
							sx={{ mt: '8px' }}
						>
							팬캐스트 바로가기
						</Typography>
					</Stack>
					<img
						src={images.SmaRightDirectionWhite}
						alt={`ic_right_direction`}
						width={36}
						height={12}
					/>
				</Stack>
			</StyledDirectVoteButton>
		);
	}

	return (
		<StyledDirectVoteButton
			component={'div'}
			disableRipple
			theme={theme}
			matchUpMd={matchUpMd}
			onClick={() => window.open(APP_FANCAST_VOTE_MAIN_URL)}
		>
			<Stack
				flexDirection={'row'}
				alignItems={'flex-end'}
				justifyContent={'space-between'}
				mb={'8px'}
			>
				<Typography variant={'caption1'} fontWeight={500} color={theme.palette.white[1]}>
					지금 바로
					<br />
					투표하러 가기
				</Typography>
				<img
					src={images.SmaRightDirectionWhite}
					alt={`ic_right_direction`}
					width={36}
					height={12}
					style={{ marginBottom: '2px' }}
				/>
			</Stack>

			<Typography variant={'caption5'} fontWeight={500} color={theme.palette.yellow[1]}>
				팬캐스트 바로가기
			</Typography>
		</StyledDirectVoteButton>
	);
};

const Sma03Component = () => {
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	if (matchUpMd) {
		return (
			<Box sx={{ pt: '40px', pb: '80px' }}>
				<Container maxWidth={1200}>
					<Grid container>
						<Grid item display={'flex'} alignItems={'center'}>
							<img src={images.SmaZoom} alt={'ic_zoom'} width={26} height={26} />
							<Typography variant={'h12'} ml={'6px'}>
								팬캐스트 투표방법을 알려드릴게요!
							</Typography>
						</Grid>
					</Grid>

					<Grid container spacing={3} pt={'28px'}>
						<Grid item md={4}>
							<FancastVoteWay item={VOTE_WAY[0]} matchUpMd={matchUpMd} />
						</Grid>
						<Grid item md={4}>
							<FancastVoteWay item={VOTE_WAY[1]} matchUpMd={matchUpMd} />
							<Box sx={{ height: '24px' }} />
							<FancastVoteWay item={VOTE_WAY[2]} matchUpMd={matchUpMd} />
						</Grid>
						<Grid item md={4}>
							<FancastVoteWay item={VOTE_WAY[3]} matchUpMd={matchUpMd} />
							<Box sx={{ height: '24px' }} />
							<FancastDirectVoteButton theme={theme} matchUpMd={matchUpMd} />
						</Grid>
					</Grid>
				</Container>
			</Box>
		);
	}

	return (
		<Grid sx={{ pt: '30px', pb: '50px' }}>
			<Container maxWidth={1200}>
				<Grid container>
					<Grid item display={'flex'} alignItems={'center'} px={'20px'}>
						<img src={images.SmaZoom} alt={'ic_zoom'} width={18} height={18} />
						<Typography variant={'caption2'} fontWeight={700} ml={'6px'}>
							팬캐스트 투표방법을 알려드릴게요!
						</Typography>
					</Grid>
				</Grid>
			</Container>

			<StyledVoteWayWrapperMobile>
				{VOTE_WAY.map((item) => (
					<FancastVoteWay key={`vote-way-${item.id}`} item={item} matchUpMd={matchUpMd} />
				))}
			</StyledVoteWayWrapperMobile>

			<Grid container>
				<FancastDirectVoteButton theme={theme} matchUpMd={matchUpMd} />
			</Grid>
		</Grid>
	);
};

export default Sma03Component;
