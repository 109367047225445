import './style/index.scss';

import { BrowserRouter } from 'react-router-dom';
import { StyledEngineProvider, CssBaseline, ThemeProvider } from '@mui/material';

import theme from './theme';

import Routes from 'routes';

import { NavigationScroll } from 'components';

import { useAxiosInterceptors } from 'hooks/use-axios-interceptors';

// ----------------------------------------------------------------------

function App() {
	useAxiosInterceptors();

	// ----------------------------------------------------------------------

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme()}>
				<CssBaseline />
				<BrowserRouter>
					<NavigationScroll>
						<Routes />
					</NavigationScroll>
				</BrowserRouter>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}

export default App;
