import {
	Box,
	ButtonBase,
	ClickAwayListener,
	Container,
	Fade,
	styled,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';

import { APP_FANCAST_VOTE_MAIN_URL } from 'constants/common';

const StyledAppItemWrapper = styled(Box)(({ theme, matchUpSm }) => ({
	position: 'absolute',
	bottom: matchUpSm ? '64px' : '61px',
	left: 0,
	zIndex: 400,
	width: '100%',
	backgroundColor: 'rgba(30, 32, 95, 0.9)',
	borderRadius: '12px'
}));

const StyledAppItemButton = styled(ButtonBase)(({ theme }) => ({
	width: '100%',
	padding: '20px 0'
}));

const StyledVoteButton = styled(ButtonBase)(({ theme }) => ({
	width: '100%',
	backgroundColor: theme.palette.purple[2],
	borderRadius: '12px',
	padding: '20px'
}));

const MainBottom = () => {
	const theme = useTheme();
	const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

	const [isOpen, setIsOpen] = useState(false);

	const handleOpenAppItem = () => {
		if (isMobile) {
			handleClickApp();
		} else {
			setIsOpen(!isOpen);
		}
	};

	const handleClickAway = () => {
		setIsOpen(false);
	};

	const handleClickApp = (type) => {
		if (isMobile) {
			window.open(APP_FANCAST_VOTE_MAIN_URL);
		} else {
			let url = '';
			if (type === 'android') {
				url =
					'https://play.google.com/store/apps/details?id=com.contentsmadang.fancast';
			} else {
				url = 'https://apps.apple.com/kr/app/id1641638840';
			}
			window.open(url);
		}
		setIsOpen(false);
	};

	return (
		<Container
			maxWidth={'lg'}
			sx={{ mt: matchUpSm ? '80px' : '50px', mb: matchUpSm ? '80px' : '20px' }}
		>
			<Box position={'relative'} px={matchUpSm ? 0 : '20px'}>
				<ClickAwayListener onClickAway={handleClickAway}>
					<StyledVoteButton
						theme={theme}
						disableRipple
						onClick={handleOpenAppItem}
					>
						<Typography
							variant={matchUpSm ? 'caption2' : 'caption3'}
							fontWeight={700}
							color={theme.palette.white[1]}
						>
							투표결과 보러가기
						</Typography>
						<Fade in={isOpen}>
							<StyledAppItemWrapper matchUpSm={matchUpSm}>
								<StyledAppItemButton
									disableRipple
									onClick={() => handleClickApp('android')}
								>
									<Typography
										variant={matchUpSm ? 'caption2' : 'caption3'}
										color={theme.palette.white[1]}
									>
										Android
									</Typography>
								</StyledAppItemButton>
								<StyledAppItemButton
									disableRipple
									onClick={() => handleClickApp('ios')}
								>
									<Typography
										variant={matchUpSm ? 'caption2' : 'caption3'}
										color={theme.palette.white[1]}
									>
										ios
									</Typography>
								</StyledAppItemButton>
							</StyledAppItemWrapper>
						</Fade>
					</StyledVoteButton>
				</ClickAwayListener>
			</Box>
		</Container>
	);
};

export default MainBottom;
