import { Fragment, useState } from 'react';

import { Box, Container, Typography, useMediaQuery, useTheme, Select, MenuItem } from '@mui/material';

import { Header, Footer } from 'views/fancast/legacy/components';

// ----------------------------------------------------------------------

const FancastPrivacyView = () => {
	const theme = useTheme();
	const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

	const [selectedValue, setSelectedValue] = useState(0);

	const handleChange = (event: any) => {
		setSelectedValue(event.target.value);
	};

	const renderContent = () => {
		switch (selectedValue) {
			case 0:
				return (
					<Typography variant="body1" mt={2}>
						<div>
						㈜퀵윈스튜디오 (이하 "회사")은 통신비밀보호법, 전기통신사업법, 정보통신망
						이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할
						관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한
						개인정보처리방침을 정하여 회원의 권익 보호에 최선을 다하고 있습니다. 본
						개인정보처리방침은 개인정보보호와 관련한 법률 또는 지침의 변경, 회사 정책의
						변화에 따라 달라질 수 있으니 회원께서는 서비스 방문 시 수시로 확인하시기
						바랍니다.
						<br />
						<br />
						본 개인정보처리방침은 Fancast 서비스에 적용됩니다.
						<br />
						<br />
						<br />
						1. 수집하는 개인정보의 항목 및 수집방법
						<br />
						<br />
						회사는 회원가입, 원활한 고객상담, 각종 서비스의 제공을 위해 아래와 같은
						개인정보를 수집하고 있습니다.
					</div>
					<div>
						<table>
							<thead>
								<tr>
									<th>수집시기</th>
									<th>구분</th>
									<th>수집항목</th>
								</tr>
								<tr>
									<td rowSpan='4'>
										이메일
										<br />
										회원가입 시
									</td>
									<td>필수</td>
									<td>
										닉네임, 사용언어, 만14세 이상 여부, 단말기식별번호(디바이스 아이디
										또는 IMEI, 맥어드레스 등)
									</td>
								</tr>
								<tr>
									<td>선택</td>
									<td>생년월일</td>
								</tr>
								<tr>
									<td>수집 및 이용목적</td>
									<td>
										- 이용자 식별 및 회원관리 및 서비스 이용
										<br />
										- 서비스 부정 이용 방지 및 중복가입확인
										<br />
										- 회원제 서비스에 따른 본인 확인 절차
										<br />- 회원 혜택, 약관 변경, 고지사항 전달, 문의/상담 및 불만 등에
										대한 민원처리
									</td>
								</tr>
								<tr>
									<td>보유·이용기간</td>
									<td>관련 법령에 따른 보존기간 또는 탈퇴 후 3개월 이내</td>
								</tr>
								<tr>
									<td rowSpan='9'>
										SNS
										<br />
										연동 가입 시
									</td>
									<td>페이스북</td>
									<td>
										(필수) 공개 프로필(이름)
										<br />
										(선택) 이메일 주소
									</td>
								</tr>
								<tr>
									<td>트위터</td>
									<td>
										(필수) 공개 프로필(이름)
										<br />
										(선택) 이메일 주소
									</td>
								</tr>
								<tr>
									<td>구글</td>
									<td>
										(필수) 공개 프로필(이름)
										<br />
										(선택) 이메일 주소
									</td>
								</tr>
								<tr>
									<td>애플</td>
									<td>
										(필수) 공개 프로필(이름)
										<br />
										(선택) 이메일 주소
									</td>
								</tr>
								<tr>
									<td>라인</td>
									<td>
										(필수) 공개 프로필(이름)
										<br />
										(선택) 이메일 주소
									</td>
								</tr>
								<tr>
									<td>카카오</td>
									<td>
										(필수) 공개 프로필(이름)
										<br />
										(선택) 이메일 주소
									</td>
								</tr>
								<tr>
									<td>네이버</td>
									<td>
										(필수) 공개 프로필(이름)
										<br />
										(선택) 이메일 주소
									</td>
								</tr>
								<tr>
									<td>수집 및 이용목적</td>
									<td>
										- 이용자 식별 및 회원관리
										<br />
										- 서비스 기본 기능 제공
										<br />
										- 서비스 제공에 관한 계약 이행
										<br />
										- 서비스 부정 이용 방지 및 중복가입확인
										<br />- 회원 혜택, 약관 변경, 고지사항 전달, 문의/상담 및 불만 등에
										대한 민원처리
									</td>
								</tr>
								<tr>
									<td>보유·이용기간</td>
									<td>관련 법령에 따른 보존기간 또는 탈퇴 후 3개월 이내</td>
								</tr>
								<tr>
									<td rowSpan='4'>서비스 이용 과정</td>
									<td>수집 방법</td>
									<td>
										- 투표서비스를 실행 또는 사용함으로써 자동으로 수집
										<br />
										- 팬캐스트 서비스를 실행 또는 사용함으로써 자동으로 수집
										<br />- 서비스 가입이나 사용 중 회원의 자발적 제공을 통한 수집
									</td>
								</tr>
								<tr>
									<td>수집 및 이용 목적</td>
									<td>
										- 서비스 기본 기능 제공
										<br />
										- 서비스 제공에 관한 계약 이행
										<br />- 신규 서비스 개발 및 마케팅 광고에서의 활용
									</td>
								</tr>
								<tr>
									<td>수집 항목</td>
									<td>
										회원 상태정보, 아이디, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용
										기록, 접속 로그, 위치정보, 접속 IP 정보, 상품 수령 정보 등
									</td>
								</tr>
								<tr>
									<td>보유·이용기간</td>
									<td>관련 법령에 따른 보존기간 또는 탈퇴 후 3개월 이내</td>
								</tr>
							</thead>
						</table>
					</div>
					<div>
						2. 개인정보의 수집 및 이용목적
						<br />
						가. 기본 기능의 제공
						<br />
						회사는 자동으로 수집되거나 회원의 자발적 제공을 통해 수집된 개인정보를
						바탕으로 서비스를 제공합니다. 또한 회사는 회원의 가입 시에 단말기 식별번호
						(디바이스 아이디 또는 IMEI, 맥어드레스 등)를 수집하고 복호화가 불가능한
						방식으로 암호화하여 저장하고 회원의 전화번호와 조합하여 개인식별을 위한 서비스
						계정으로 사용하게 됩니다. <br />
						<br />
						나. 서비스 제공에 관한 계약 이행 <br />
						콘텐츠 제공, 특정 맞춤 서비스 제공, 물품배송 등 발송, 본인인증 등
						<br />
						<br />
						다. 회원관리
						<br />
						회원제 서비스 이용에 따른 본인확인, 서비스 제공과 관련한 회원 연령의 확인,
						개인식별, 불량회원(서비스 이용약관 위반 등으로 인하여 영구이용정지 회원 및
						계약 해지된 영구이용정지 회원)의 부정 이용방지와 비인가 사용방지, 가입의사
						확인, 가입 및 가입횟수 제한, 서비스 대상 연령 만14세 이상 여부 확인, 만14세
						미만 아동 개인정보 수집 시 법정 대리인 동의여부 확인, 추후 법정 대리인
						본인확인, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달
						<br />
						<br />
						라. 신규 서비스 개발 및 마케팅 광고에서의 활용
						<br />
						신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고
						게재, 서비스의 유효성 확인, 이벤트 및 광고성 정보 제공 및 참여기회 제공,
						접속빈도 파악, 회원의 서비스이용에 대한 통계
						<br />
						<br />
						<br />
						3. 개인정보의 공유 및 제공
						<br />
						회사는 회원들의 개인정보를 “개인정보의 수집 및 이용목적”에서 고지한 범위
						내에서 사용하며, 회원의 사전 동의 없이는 동 범위를 초과하여 이용하거나
						원칙적으로 회원의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는
						주의를 기울여 개인정보를 이용 및 제공할 수 있습니다.
						<br />
						<br />
						가. 하트 및 아이템 적립을 위한 설치 및 실행여부 판단 목적
						<br />
						제공받는 자 및 제공 내용
						<br />
						- 제공받는자:광고주
						<br />
						- 제공항목:암호화된 디바이스 아이디
						<br />
						- 보유 및 이용기간:광고 종료 시까지
						<br />
						<br />
						나. 제3자 마케팅 활용 동의 목적
						<br />
						서비스에 가입한 회원님들에게 보다 다양한 정보를 안내하기 위하여 마케팅활용에
						선택적으로 동의하신 회원님에 한하여 본 활용동의서에 동의한 회원님의 개인정보를
						파트너사에 제공합니다. 회원님께서는 제3자 마케팅 활용동의서에 동의를 거부하실
						수 있으며, 동의 거부 시 회원가입은 가능하나, 각종 광고 및 이벤트 안내 등의
						서비스가 제한됩니다. 이에 대한 자세한 내용은 “제3자 마케팅 활용동의서” 약관
						본문을 확인하시기 바랍니다.
						<br />
						<br />
						다. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라
						수사기관의 요구가 있는 경우
						<br />
						<br />
						4. 개인정보의 보유 및 이용기간
						<br />
						회원의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이
						파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안
						보존합니다. 보존항목은 다음과 같습니다.
						<br />
						- 이메일, 아이디, SNS ID, SNS분류, 생년월일, 성별, 이름, 휴대폰 번호,
						거주지역, 회원으로 인한 2차 유입 UV기록, 제휴 콘텐츠 및 서비스 이용내역,
						투표권 발급내역, SNS연동 기록 등<br />
						<br />
						가. 회사 내부 방침에 의한 정보보유 사유
						<br />
						- 부정이용기록
						<br />
						보존 이유:부정 이용 방지
						<br />
						보존 기간:1년
						<br />
						<br />
						나. 관련법령에 의한 정보보유 사유
						<br />
						상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여
						보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를
						보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며
						보존기간은 아래와 같습니다.
						<br />
						- 계약 또는 청약철회 등에 관한 기록
						<br />
						보존 이유:전자상거래 등에서의 소비자보호에 관한 법률
						<br />
						보존 기간:5년 <br />
						- 대금결제 및 재화 등의 공급에 관한 기록
						<br />
						보존 이유:전자상거래 등에서의 소비자보호에 관한 법률
						<br />
						보존 기간:5년 <br />
						- 소비자의 불만 또는 분쟁처리에 관한 기록 <br />
						보존 이유:전자상거래 등에서의 소비자보호에 관한 법률 <br />
						보존 기간:3년
						<br />
						- 본인확인에 관한 기록
						<br />
						보존 이유:정보통신망 이용촉진 및 정보보호 등에 관한 법률 <br />
						보존 기간:6개월
						<br />
						- 방문에 관한 기록
						<br />
						보존 이유:통신비밀보호법
						<br />
						보존 기간:3개월
						<br />
						<br />
						5. 개인정보 파기절차 및 방법
						<br />
						회원의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이
						파기합니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.
						<br />
						<br />
						가. 파기절차
						<br />
						- 회원이 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로
						옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호
						사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다.
						<br />
						- 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로
						이용되지 않습니다.
						<br />
						<br />
						나. 파기방법
						<br />
						- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
						<br />
						- 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을
						사용하여 삭제합니다.
						<br />
						<br />
						<br />
						6. 휴면계정의 개인정보 분리 저장ᆞ관리
						<br />
						회사는 서비스 이용약관에 따라 최근 1년 동안 서비스를 이용하지 않은 휴면계정의
						개인정보를 별도로 분리보관합니다.
						<br />
						<br />
						휴면계정 전환으로 개인정보가 분리 저장∙관리될 경우 모든 서비스 이용이
						불가능하며, 다시 서비스를 이용하기 위해서는 서비스에 로그인하여 신청이
						가능합니다.
						<br />
						<br />
						기간 만료일 전까지 서비스를 다시 이용하실 경우 휴면계정 전환이 취소되니,
						휴면계정 전환과 개인정보 분리 보관을 원치 않으시는 경우 서비스에 로그인하거나
						잠깐이라도 서비스 등을 이용하시면 됩니다.
						<br />
						<br />
						7. 만14세 미만 아동의 개인정보보호
						<br />
						본 플랫폼은 아동(내국인의 경우 만14세 미만, 외국인의 경우 만16세 미만)을
						대상으로 하지 않습니다. 당사가 아동으로부터 개인정보를 수집한 사실을 인지하게
						되는 경우, 당사는 이러한 정보를 삭제하고 해당 이용자의 계정을 해지시킬
						것입니다. 당사가 아동으로부터 정보를 수집하였다고 생각되는 경우 본 약관 내
						개인정보관리책임자의 연락처로 연락 주시기 바랍니다.
						<br />
						<br />
						<br />
						8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
						<br />
						회사는 계정정보를 생성하기 위해 회원이 서비스 실행 시 단말기 식별번호
						(디바이스 아이디 또는 IMEI, 맥어드레스 등)를 자동으로 수집하게 됩니다.
						<br />
						회원이 단말기 식별번호를 자동으로 수집하는 것을 거부하는 경우 서비스를 이용할
						수 없습니다.
						<br />
						<br />
						9. 개인정보의 기술적/관리적 보호 대책
						<br />
						회사는 회원들의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조
						또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을
						강구하고 있습니다.
						<br />
						<br />
						가. 해킹 등에 대비한 대책
						<br />
						회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나
						훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서
						자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 회원들의 개인정보나
						자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통하여
						네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고
						침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타
						시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고
						있습니다.
						<br />
						<br />
						나. 취급 직원의 최소화 및 교육
						<br />
						회사의 개인정보관련 처리 직원은 담당자에 한정시키고 있고 이를 위한 별도의
						비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을
						통하여 서비스 개인정보처리방침의 준수를 항상 강조하고 있습니다.
						<br />
						<br />
						다. 개인정보보호전담기구의 운영
						<br />
						회사는 사내 개인정보보호전담기구 등을 통하여 서비스 개인정보처리방침의
						이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로
						잡을 수 있도록 노력하고 있습니다. 단, 회원 본인의 부주의나 회사의 고의 또는
						중대한 과실이 아닌 사유로 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의
						책임을 지지 않습니다.
						<br />
						<br />
						10. 개인정보관리책임자 및 담당자의 연락처
						<br />
						귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을
						고객센터를 통해 신고하실 수 있습니다. 회사는 회원들의 신고사항에 대해 신속하게
						충분한 답변을 드릴 것입니다.
						<br />
						<br />
						개인정보보호책임자
						<br />
						- 성명:윤용수
						<br />
						- 이메일 :fancastofficial@gmail.com
						<br />
						<br />
						개인정보 고충처리 담당 부서
						<br />
						- 부서명:퀵윈스튜디오 팬캐스트팀
						<br />
						- 이메일:fancastofficial@gmail.com
						<br />
						<br />
						기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
						문의하시기 바랍니다.
						<br />
						- 개인정보침해신고센터 (www.118.or.kr /국번없이118)
						<br />
						- 정보보호마크인증위원회 (www.eprivacy.or.kr / 02-580-0533~4) <br />- 대검찰청
						첨단범죄수사과 (http://www.spo.go.kr / 02-3480-2000) <br />
						- 경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-392-0330)
						<br />
						<br />
						11. 개인정보 처리 위탁관리
						<br />
						회사는 서비스 이용 및 계약의 이행, 회원의 편의 증진을 목적으로 필요 시
						개인정보처리방침에 공개한 범위 내에서 개인정보의 처리를 국내외 전문업체에
						위탁하거나 전문 플랫폼을 활용하고 있습니다.
						<br />
						<br />
						- ㈜퀵윈스튜디오
						<br />
						업무 내용 :서비스 제공을 위한 시스템 개발 및 운영
						<br />
						- Amazon Web Service
						<br />
						업무 내용 :AWS를 통한 시스템 관리
						<br />
						<br />
						회사는 위탁 계약 체결 시 위탁업무 수행목적 외 개인정보 처리금지, 기술적•관리적
						보호조치, 재위탁 제한, 수탁자에 대한 관리•감독, 손해배상 등 책임에 관한 사항을
						계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는 지를 감독하고
						있습니다. <br />
						위탁 업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보처리방침을
						통하여 공개하도록 하겠습니다.
						<br />
						<br />
						12. 고지의 의무
						<br />
						현 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 시에는 시행일자 최소
						7일전부터 서비스 내 “공지사항” 화면을 통해 공고할 것입니다.
						<br />
						<br />본 약관은 2025년 3월 10일부터 적용됩니다.
					</div>
					</Typography>
				);
			case 1:
				return (
					<Typography variant="body2" mt={2}>
						<div>
						㈜콘텐츠마당 (이하 "회사")은 통신비밀보호법, 전기통신사업법, 정보통신망
						이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할
						관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한
						개인정보처리방침을 정하여 회원의 권익 보호에 최선을 다하고 있습니다. 본
						개인정보처리방침은 개인정보보호와 관련한 법률 또는 지침의 변경, 회사 정책의
						변화에 따라 달라질 수 있으니 회원께서는 서비스 방문 시 수시로 확인하시기
						바랍니다.
						<br />
						<br />
						본 개인정보처리방침은 Fancast 서비스에 적용됩니다.
						<br />
						<br />
						<br />
						1. 수집하는 개인정보의 항목 및 수집방법
						<br />
						<br />
						회사는 회원가입, 원활한 고객상담, 각종 서비스의 제공을 위해 아래와 같은
						개인정보를 수집하고 있습니다.
					</div>
					<div>
						<table>
							<thead>
								<tr>
									<th>수집시기</th>
									<th>구분</th>
									<th>수집항목</th>
								</tr>
								<tr>
									<td rowSpan='4'>
										이메일
										<br />
										회원가입 시
									</td>
									<td>필수</td>
									<td>
										닉네임, 사용언어, 만14세 이상 여부, 단말기식별번호(디바이스 아이디
										또는 IMEI, 맥어드레스 등)
									</td>
								</tr>
								<tr>
									<td>선택</td>
									<td>생년월일</td>
								</tr>
								<tr>
									<td>수집 및 이용목적</td>
									<td>
										- 이용자 식별 및 회원관리 및 서비스 이용
										<br />
										- 서비스 부정 이용 방지 및 중복가입확인
										<br />
										- 회원제 서비스에 따른 본인 확인 절차
										<br />- 회원 혜택, 약관 변경, 고지사항 전달, 문의/상담 및 불만 등에
										대한 민원처리
									</td>
								</tr>
								<tr>
									<td>보유·이용기간</td>
									<td>관련 법령에 따른 보존기간 또는 탈퇴 후 3개월 이내</td>
								</tr>
								<tr>
									<td rowSpan='9'>
										SNS
										<br />
										연동 가입 시
									</td>
									<td>페이스북</td>
									<td>
										(필수) 공개 프로필(이름)
										<br />
										(선택) 이메일 주소
									</td>
								</tr>
								<tr>
									<td>트위터</td>
									<td>
										(필수) 공개 프로필(이름)
										<br />
										(선택) 이메일 주소
									</td>
								</tr>
								<tr>
									<td>구글</td>
									<td>
										(필수) 공개 프로필(이름)
										<br />
										(선택) 이메일 주소
									</td>
								</tr>
								<tr>
									<td>애플</td>
									<td>
										(필수) 공개 프로필(이름)
										<br />
										(선택) 이메일 주소
									</td>
								</tr>
								<tr>
									<td>라인</td>
									<td>
										(필수) 공개 프로필(이름)
										<br />
										(선택) 이메일 주소
									</td>
								</tr>
								<tr>
									<td>카카오</td>
									<td>
										(필수) 공개 프로필(이름)
										<br />
										(선택) 이메일 주소
									</td>
								</tr>
								<tr>
									<td>네이버</td>
									<td>
										(필수) 공개 프로필(이름)
										<br />
										(선택) 이메일 주소
									</td>
								</tr>
								<tr>
									<td>수집 및 이용목적</td>
									<td>
										- 이용자 식별 및 회원관리
										<br />
										- 서비스 기본 기능 제공
										<br />
										- 서비스 제공에 관한 계약 이행
										<br />
										- 서비스 부정 이용 방지 및 중복가입확인
										<br />- 회원 혜택, 약관 변경, 고지사항 전달, 문의/상담 및 불만 등에
										대한 민원처리
									</td>
								</tr>
								<tr>
									<td>보유·이용기간</td>
									<td>관련 법령에 따른 보존기간 또는 탈퇴 후 3개월 이내</td>
								</tr>
								<tr>
									<td rowSpan='4'>서비스 이용 과정</td>
									<td>수집 방법</td>
									<td>
										- 투표서비스를 실행 또는 사용함으로써 자동으로 수집
										<br />
										- 팬캐스트 서비스를 실행 또는 사용함으로써 자동으로 수집
										<br />- 서비스 가입이나 사용 중 회원의 자발적 제공을 통한 수집
									</td>
								</tr>
								<tr>
									<td>수집 및 이용 목적</td>
									<td>
										- 서비스 기본 기능 제공
										<br />
										- 서비스 제공에 관한 계약 이행
										<br />- 신규 서비스 개발 및 마케팅 광고에서의 활용
									</td>
								</tr>
								<tr>
									<td>수집 항목</td>
									<td>
										회원 상태정보, 아이디, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용
										기록, 접속 로그, 위치정보, 접속 IP 정보, 상품 수령 정보 등
									</td>
								</tr>
								<tr>
									<td>보유·이용기간</td>
									<td>관련 법령에 따른 보존기간 또는 탈퇴 후 3개월 이내</td>
								</tr>
							</thead>
						</table>
					</div>
					<div>
						2. 개인정보의 수집 및 이용목적
						<br />
						가. 기본 기능의 제공
						<br />
						회사는 자동으로 수집되거나 회원의 자발적 제공을 통해 수집된 개인정보를
						바탕으로 서비스를 제공합니다. 또한 회사는 회원의 가입 시에 단말기 식별번호
						(디바이스 아이디 또는 IMEI, 맥어드레스 등)를 수집하고 복호화가 불가능한
						방식으로 암호화하여 저장하고 회원의 전화번호와 조합하여 개인식별을 위한 서비스
						계정으로 사용하게 됩니다. <br />
						<br />
						나. 서비스 제공에 관한 계약 이행 <br />
						콘텐츠 제공, 특정 맞춤 서비스 제공, 물품배송 등 발송, 본인인증 등
						<br />
						<br />
						다. 회원관리
						<br />
						회원제 서비스 이용에 따른 본인확인, 서비스 제공과 관련한 회원 연령의 확인,
						개인식별, 불량회원(서비스 이용약관 위반 등으로 인하여 영구이용정지 회원 및
						계약 해지된 영구이용정지 회원)의 부정 이용방지와 비인가 사용방지, 가입의사
						확인, 가입 및 가입횟수 제한, 서비스 대상 연령 만14세 이상 여부 확인, 만14세
						미만 아동 개인정보 수집 시 법정 대리인 동의여부 확인, 추후 법정 대리인
						본인확인, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달
						<br />
						<br />
						라. 신규 서비스 개발 및 마케팅 광고에서의 활용
						<br />
						신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고
						게재, 서비스의 유효성 확인, 이벤트 및 광고성 정보 제공 및 참여기회 제공,
						접속빈도 파악, 회원의 서비스이용에 대한 통계
						<br />
						<br />
						<br />
						3. 개인정보의 공유 및 제공
						<br />
						회사는 회원들의 개인정보를 “개인정보의 수집 및 이용목적”에서 고지한 범위
						내에서 사용하며, 회원의 사전 동의 없이는 동 범위를 초과하여 이용하거나
						원칙적으로 회원의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는
						주의를 기울여 개인정보를 이용 및 제공할 수 있습니다.
						<br />
						<br />
						가. 하트 및 아이템 적립을 위한 설치 및 실행여부 판단 목적
						<br />
						제공받는 자 및 제공 내용
						<br />
						- 제공받는자:광고주
						<br />
						- 제공항목:암호화된 디바이스 아이디
						<br />
						- 보유 및 이용기간:광고 종료 시까지
						<br />
						<br />
						나. 제3자 마케팅 활용 동의 목적
						<br />
						서비스에 가입한 회원님들에게 보다 다양한 정보를 안내하기 위하여 마케팅활용에
						선택적으로 동의하신 회원님에 한하여 본 활용동의서에 동의한 회원님의 개인정보를
						파트너사에 제공합니다. 회원님께서는 제3자 마케팅 활용동의서에 동의를 거부하실
						수 있으며, 동의 거부 시 회원가입은 가능하나, 각종 광고 및 이벤트 안내 등의
						서비스가 제한됩니다. 이에 대한 자세한 내용은 “제3자 마케팅 활용동의서” 약관
						본문을 확인하시기 바랍니다.
						<br />
						<br />
						다. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라
						수사기관의 요구가 있는 경우
						<br />
						<br />
						4. 개인정보의 보유 및 이용기간
						<br />
						회원의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이
						파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안
						보존합니다. 보존항목은 다음과 같습니다.
						<br />
						- 이메일, 아이디, SNS ID, SNS분류, 생년월일, 성별, 이름, 휴대폰 번호,
						거주지역, 회원으로 인한 2차 유입 UV기록, 제휴 콘텐츠 및 서비스 이용내역,
						투표권 발급내역, SNS연동 기록 등<br />
						<br />
						가. 회사 내부 방침에 의한 정보보유 사유
						<br />
						- 부정이용기록
						<br />
						보존 이유:부정 이용 방지
						<br />
						보존 기간:1년
						<br />
						<br />
						나. 관련법령에 의한 정보보유 사유
						<br />
						상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여
						보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를
						보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며
						보존기간은 아래와 같습니다.
						<br />
						- 계약 또는 청약철회 등에 관한 기록
						<br />
						보존 이유:전자상거래 등에서의 소비자보호에 관한 법률
						<br />
						보존 기간:5년 <br />
						- 대금결제 및 재화 등의 공급에 관한 기록
						<br />
						보존 이유:전자상거래 등에서의 소비자보호에 관한 법률
						<br />
						보존 기간:5년 <br />
						- 소비자의 불만 또는 분쟁처리에 관한 기록 <br />
						보존 이유:전자상거래 등에서의 소비자보호에 관한 법률 <br />
						보존 기간:3년
						<br />
						- 본인확인에 관한 기록
						<br />
						보존 이유:정보통신망 이용촉진 및 정보보호 등에 관한 법률 <br />
						보존 기간:6개월
						<br />
						- 방문에 관한 기록
						<br />
						보존 이유:통신비밀보호법
						<br />
						보존 기간:3개월
						<br />
						<br />
						5. 개인정보 파기절차 및 방법
						<br />
						회원의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이
						파기합니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.
						<br />
						<br />
						가. 파기절차
						<br />
						- 회원이 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로
						옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호
						사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다.
						<br />
						- 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로
						이용되지 않습니다.
						<br />
						<br />
						나. 파기방법
						<br />
						- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
						<br />
						- 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을
						사용하여 삭제합니다.
						<br />
						<br />
						<br />
						6. 휴면계정의 개인정보 분리 저장ᆞ관리
						<br />
						회사는 서비스 이용약관에 따라 최근 1년 동안 서비스를 이용하지 않은 휴면계정의
						개인정보를 별도로 분리보관합니다.
						<br />
						<br />
						휴면계정 전환으로 개인정보가 분리 저장∙관리될 경우 모든 서비스 이용이
						불가능하며, 다시 서비스를 이용하기 위해서는 서비스에 로그인하여 신청이
						가능합니다.
						<br />
						<br />
						기간 만료일 전까지 서비스를 다시 이용하실 경우 휴면계정 전환이 취소되니,
						휴면계정 전환과 개인정보 분리 보관을 원치 않으시는 경우 서비스에 로그인하거나
						잠깐이라도 서비스 등을 이용하시면 됩니다.
						<br />
						<br />
						7. 만14세 미만 아동의 개인정보보호
						<br />
						본 플랫폼은 아동(내국인의 경우 만14세 미만, 외국인의 경우 만16세 미만)을
						대상으로 하지 않습니다. 당사가 아동으로부터 개인정보를 수집한 사실을 인지하게
						되는 경우, 당사는 이러한 정보를 삭제하고 해당 이용자의 계정을 해지시킬
						것입니다. 당사가 아동으로부터 정보를 수집하였다고 생각되는 경우 본 약관 내
						개인정보관리책임자의 연락처로 연락 주시기 바랍니다.
						<br />
						<br />
						<br />
						8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
						<br />
						회사는 계정정보를 생성하기 위해 회원이 서비스 실행 시 단말기 식별번호
						(디바이스 아이디 또는 IMEI, 맥어드레스 등)를 자동으로 수집하게 됩니다.
						<br />
						회원이 단말기 식별번호를 자동으로 수집하는 것을 거부하는 경우 서비스를 이용할
						수 없습니다.
						<br />
						<br />
						9. 개인정보의 기술적/관리적 보호 대책
						<br />
						회사는 회원들의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조
						또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을
						강구하고 있습니다.
						<br />
						<br />
						가. 해킹 등에 대비한 대책
						<br />
						회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나
						훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서
						자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 회원들의 개인정보나
						자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통하여
						네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고
						침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타
						시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고
						있습니다.
						<br />
						<br />
						나. 취급 직원의 최소화 및 교육
						<br />
						회사의 개인정보관련 처리 직원은 담당자에 한정시키고 있고 이를 위한 별도의
						비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을
						통하여 서비스 개인정보처리방침의 준수를 항상 강조하고 있습니다.
						<br />
						<br />
						다. 개인정보보호전담기구의 운영
						<br />
						회사는 사내 개인정보보호전담기구 등을 통하여 서비스 개인정보처리방침의
						이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로
						잡을 수 있도록 노력하고 있습니다. 단, 회원 본인의 부주의나 회사의 고의 또는
						중대한 과실이 아닌 사유로 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의
						책임을 지지 않습니다.
						<br />
						<br />
						10. 개인정보관리책임자 및 담당자의 연락처
						<br />
						귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을
						고객센터를 통해 신고하실 수 있습니다. 회사는 회원들의 신고사항에 대해 신속하게
						충분한 답변을 드릴 것입니다.
						<br />
						<br />
						개인정보보호책임자
						<br />
						- 성명:윤용수
						<br />
						- 이메일 :fancastofficial@gmail.com
						<br />
						<br />
						개인정보 고충처리 담당 부서
						<br />
						- 부서명:플랫폼사업본부
						<br />
						- 이메일:fancastofficial@gmail.com
						<br />
						<br />
						기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
						문의하시기 바랍니다.
						<br />
						- 개인정보침해신고센터 (www.118.or.kr /국번없이118)
						<br />
						- 정보보호마크인증위원회 (www.eprivacy.or.kr / 02-580-0533~4) <br />- 대검찰청
						첨단범죄수사과 (http://www.spo.go.kr / 02-3480-2000) <br />
						- 경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-392-0330)
						<br />
						<br />
						11. 개인정보 처리 위탁관리
						<br />
						회사는 서비스 이용 및 계약의 이행, 회원의 편의 증진을 목적으로 필요 시
						개인정보처리방침에 공개한 범위 내에서 개인정보의 처리를 국내외 전문업체에
						위탁하거나 전문 플랫폼을 활용하고 있습니다.
						<br />
						<br />
						- ㈜콘텐츠마당
						<br />
						업무 내용 :서비스 제공을 위한 시스템 개발 및 운영
						<br />
						- Amazon Web Service
						<br />
						업무 내용 :AWS를 통한 시스템 관리
						<br />
						<br />
						회사는 위탁 계약 체결 시 위탁업무 수행목적 외 개인정보 처리금지, 기술적•관리적
						보호조치, 재위탁 제한, 수탁자에 대한 관리•감독, 손해배상 등 책임에 관한 사항을
						계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는 지를 감독하고
						있습니다. <br />
						위탁 업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보처리방침을
						통하여 공개하도록 하겠습니다.
						<br />
						<br />
						12. 고지의 의무
						<br />
						현 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 시에는 시행일자 최소
						7일전부터 서비스 내 “공지사항” 화면을 통해 공고할 것입니다.
						<br />
						<br />본 약관은 2022년 11월 2일부터 적용됩니다.
					</div>
					</Typography>
				);
		}
	}
	// ----------------------------------------------------------------------

	return (
		<Fragment>
			<Header />
			<Container
				component={'main'}
				maxWidth={'lg'}
				className={'Fancast-Legacy'}
				sx={{ pt: 5, pb: 10 }}
			>
				<Box px={matchUpSm ? 0 : '20px'}>
					<Typography variant={'h11'} fontWeight={600} mb={2}>
						개인정보처리방침
					</Typography>

					<Select value={selectedValue} onChange={handleChange}>
						<MenuItem value={0}>2025-03-06</MenuItem>
						<MenuItem value={1}>2022-10-31</MenuItem>
					</Select>

					{renderContent()}					
				</Box>
			</Container>
			<Footer />
		</Fragment>
	);
};

export default FancastPrivacyView;
