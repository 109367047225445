import { createTheme } from '@mui/material';

import typography from 'theme/typography';
import palette from 'theme/palette';

// ----------------------------------------------------------------------

export const theme = () => {
	const options = {
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 900,
				lg: 1140,
				1200: 1200,
				xl: 1536
			}
		},
		typography: typography(),
		palette: palette()
	};

	const theme = createTheme(options);

	return theme;
};

export default theme;
