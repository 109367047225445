const prod = {
	API_ENDPOINT_URL: 'https://api.fanca.io'
};

const dev = {
	API_ENDPOINT_URL: 'https://dev-api.fanca.io'
};

const local = {
	API_ENDPOINT_URL: 'http://localhost:4000'
};

const getEnv = () => {
	// customize를 위한 `NODE_ENV` 환경변수 대체됨(NODE_ENV => REACT_APP_NODE_ENV)
	// local 서버기동시 npm run local, dev/prod 빌드시 npm run build:dev 또는 npm run build:prod 사용
	switch (process.env.REACT_APP_NODE_ENV) {
		case 'development':
			return dev;
		case 'production':
			return prod;
		case 'local':
			return local;
		default:
			break;
	}
};

export const env = getEnv();
