import {
	Box,
	ButtonBase,
	Container,
	Grid,
	Link,
	styled,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const StyledTermsButton = styled(ButtonBase)(({ theme }) => ({}));

const Footer = () => {
	const theme = useTheme();
	const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

	const navigate = useNavigate();

	const handleMoveService = () => {
		navigate('/service');
	};

	const handleMovePrivacy = () => {
		navigate('/privacy');
	};

	return (
		<Box component={'footer'} bgcolor={theme.palette.legacy.darkNavy} pt={10} pb={8}>
			<Container maxWidth={'lg'}>
				<Grid container mb={2}>
					<Box px={matchUpSm ? 0 : '20px'}>
						<ButtonBase disableRipple onClick={handleMoveService}>
							<Typography
								variant={'caption2'}
								color={theme.palette.white[1]}
								fontWeight={600}
							>
								이용약관
							</Typography>
						</ButtonBase>
						<ButtonBase sx={{ ml: 2 }} onClick={handleMovePrivacy}>
							<Typography
								variant={'caption2'}
								color={theme.palette.white[1]}
								fontWeight={600}
							>
								개인정보처리방침
							</Typography>
						</ButtonBase>
						<Grid mt={2}>
							<Typography
								variant={'caption4'}
								color={theme.palette.white[1]}
								lineHeight={1.8}
							>
								사업자등록번호 : 272-87-03262 ㅣ 통신판매업신고번호 :
								제 2024-성남분당A-0657 호 ㅣ 주식회사 퀵윈스튜디오 ㅣ 대표이사 박준현
								<br />
								경기도 성남시 분당구 대왕판교로645번길 14, 3층(삼평동, 네오위즈) ㅣ © 2025
								QUICKWINSTUDIO. ALL Rights Reserved.
							</Typography>
						</Grid>
					</Box>
				</Grid>
			</Container>
		</Box>
	);
};

export default Footer;
