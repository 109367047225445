import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
	Header,
	Footer,
	SmaRealTrophy,
	Banner,
	Sma01Component,
	Sma02Component,
	Sma03Component,
	Sma04Component,
	Sma05Component,
	Sma06Component,
	SmaPolicyModal,
	SmaRanking01Component
} from 'views/sma/components';

// ----------------------------------------------------------------------

const SmaMonthRootView = () => {
	const { pathname } = useLocation();

	const [isOpenModal, setIsOpenModal] = useState(false);

	const handleOpenPolicyModal = () => {
		setIsOpenModal(!isOpenModal);
	};

	// ----------------------------------------------------------------------

	return (
		<div>
			<Header />
			{pathname === '/sma/month/about' ? (
				<>
					<div>
						<SmaRealTrophy />
						<Banner />
						<div id={'smaMonth01'}>
							<Sma01Component />
						</div>
					</div>
					<div id={'smaMonth02'}>
						<Sma02Component />
					</div>
					<div id={'smaMonth03'}>
						<Sma03Component />
					</div>
					<div id={'smaMonth04'}>
						<Sma04Component />
						<Sma05Component handleOpenPolicyModal={handleOpenPolicyModal} />
						<SmaPolicyModal isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
					</div>
					<Sma06Component />
				</>
			) : (
				<>
					<div>
						<SmaRealTrophy />
						<Banner />
					</div>
					<div id={'smaMonth05'}>
						<SmaRanking01Component />
					</div>
					<Sma06Component />
				</>
			)}
			<Footer />
		</div>
	);
};

export default SmaMonthRootView;
