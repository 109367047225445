import images from 'assets/images';
import theme from 'theme';

// ----------------------------------------------------------------------

// 메뉴
export const MENUS = [
	{ id: 'smaMonth01', text: '소개', url: '/sma/month/about' },
	{ id: 'smaMonth02', text: '선정 방식', url: '/sma/month/about' },
	{ id: 'smaMonth03', text: '투표 방법', url: '/sma/month/about' },
	{ id: 'smaMonth04', text: '투표 정책', url: '/sma/month/about' },
	{ id: 'smaMonth05', text: '순위 확인', url: '/sma/month' }
];

// SMARanking01Component - 부문별 순위 확인
export const SMA_AWARD_RANKING = [
	{
		id: 1,
		nomineeTitle: '월드 트렌드 아티스트'
	},
	{
		id: 2,
		nomineeTitle: '팬 초이스 오브 더 이어'
	}
];

// SMA02Component - 1위 선정 방식
export const CALCULATION = [
	{
		id: 'fancast',
		title: '팬캐스트',
		grid: {
			md: 4.5
		},
		ratio: 50,
		position: '50%',
		image: images.FancastLogoHorizontal,
		imageSize: {
			sm: {
				width: 173,
				height: 44
			},
			md: {
				width: 238,
				height: 62
			}
		},
		borderColor: theme().palette.grey[6],
		backgroundColor: theme().palette.white[1]
	},
	{
		id: 'idolChamp',
		title: '아이돌챔프',
		grid: {
			md: 4
		},
		ratio: 30,
		position: '20%',
		image: images.SmaIdolChampLogoHorizontal,
		imageSize: {
			sm: {
				width: 219,
				height: 48
			},
			md: {
				width: 278,
				height: 61
			}
		},
		borderColor: theme().palette.grey[5],
		backgroundColor: theme().palette.grey[5]
	},
	{
		id: 'podoal',
		title: '포도알',
		grid: {
			md: 'auto'
		},
		ratio: 20,
		position: '0',
		image: images.SmaPodoalLogoHorizontal,
		imageSize: {
			sm: {
				width: 145,
				height: 40
			},
			md: {
				width: 165,
				height: 45
			}
		},
		borderColor: theme().palette.grey[5],
		backgroundColor: theme().palette.grey[5]
	}
];

// SMA03Component - 투표방법

export const VOTE_WAY = [
	{
		id: 1,
		text: `팬캐스트 앱 다운로드 후\n회원가입을 진행해주세요`,
		image: {
			sm: images.SmaVoteOneSm,
			md: images.SmaVoteOne
		},
		imageSize: {
			sm: {
				width: 179,
				height: 292,
				pl: '28px',
				pr: '48px'
			},
			md: {
				width: 228,
				height: 372,
				pl: '36px',
				pr: '78px'
			}
		},
		borderColor: theme().palette.grey[5],
		backgroundColor: theme().palette.grey[5]
	},
	{
		id: 2,
		text: `오른쪽 위 아이콘을 눌러\n스토어에 접속하세요`,
		image: {
			sm: images.SmaVoteTwoSm,
			md: images.SmaVoteTwo
		},
		imageSize: {
			sm: {
				width: 220,
				height: 292,
				pl: '28px',
				pr: '7px'
			},
			md: {
				width: 251,
				height: 138,
				pl: '36px',
				pr: '55px'
			}
		},
		borderColor: theme().palette.grey[5],
		backgroundColor: theme().palette.grey[5]
	},
	{
		id: 3,
		text: `투표에 필요한 골드하트를\n무료로 얻을 수 있어요`,
		image: {
			sm: images.SmaVoteThreeSm,
			md: images.SmaVoteThree
		},
		imageSize: {
			sm: {
				width: 179,
				height: 292,
				pl: '28px',
				pr: '48px'
			},
			md: {
				width: 228,
				height: 316,
				pl: '36px',
				pr: '78px'
			}
		},
		borderColor: theme().palette.grey[5],
		backgroundColor: theme().palette.grey[5]
	},
	{
		id: 4,
		text: `획득한 골드하트로\n내 아티스트에 투표하세요!`,
		image: {
			sm: images.SmaVoteFourSm,
			md: images.SmaVoteFour
		},
		imageSize: {
			sm: {
				width: 220,
				height: 292,
				pl: '28px',
				pr: '7px'
			},
			md: {
				width: 263,
				height: 385,
				pl: '36px',
				pr: '43px'
			}
		},
		borderColor: theme().palette.grey[6],
		backgroundColor: theme().palette.white[1]
	}
];

// SMA04Component - 투표 정책(필요 하트 및 최대 투표 수)
export const VOTE_POLICY = [
	{
		id: 1,
		title: '필요 골드하트 수',
		subtitle: '(팬캐스트)',
		prefixValueText: '1표당',
		valueText: '100개',
		image: images.SmaGoldHeart,
		imageSize: {
			sm: {
				width: 40,
				height: 34
			},
			md: {
				width: 52,
				height: 45
			}
		}
	},
	{
		id: 2,
		title: '1일 최대 투표수',
		subtitle: '',
		prefixValueText: '1계정당',
		valueText: '100표',
		image: images.SmaMaxVote,
		imageSize: {
			sm: {
				width: 40,
				height: 26
			},
			md: {
				width: 52,
				height: 35
			}
		}
	}
];

// SMA05Component - 투표 정책(투표 부문 및 기간)
export const VOTE_AWARD = [
	{
		id: 1,
		tagText: '#부문1_그룹',
		title: '월드 트렌드 아티스트',
		subtitle: 'World Trend Artist',
		image: images.SmaWorldTrend,
		imageSize: {
			sm: {
				width: 109,
				height: 59,
				padding: '21px 6px 20px 149px'
			},
			md: {
				width: 109,
				height: 59,
				padding: '43px 55px 56px 55px'
			}
		},
		periodText: '매월 1-2주차'
	},
	{
		id: 2,
		tagText: '#부문2_솔로',
		title: '팬 초이스 오브 더 이어',
		subtitle: 'Fan Choice of the Year',
		image: images.SmaFanChoice,
		imageSize: {
			sm: {
				width: 100,
				height: 70,
				padding: '16px 10px 14px 150px'
			},
			md: {
				width: 100,
				height: 70,
				padding: '36px 59px 50px 59px'
			}
		},
		periodText: '매월 3-4주차'
	}
];

// 투표 정책 상세 정보
export const VOTE_POLICY_DETAIL = {
	title: '서울가요대상 이달의 아티스트 투표 안내',
	content: [
		{
			heading: '서울가요대상 이달의 아티스트란?',
			list: [
				'이달 가장 많은 팬에게 사랑받는 아티스트를 글로벌 팬 투표를 100% 반영하여 선정합니다.',
				'[월드 트렌드 아티스트]와 [팬 초이스 오브 더 이어] 투표는 2주씩 나누어 진행되며, 각 부문 1위 발표는 익월 초에 동시 발표됩니다.',
				'매월 누적 1위 [월드 트렌드 아티스트]와 [팬 초이스 오브 더 이어] 아티스트에게는 이달의 아티스트 트로피를 수여합니다.',
				'최종 [월드 트렌드 아티스트] 와 [팬 초이스 오브 더 이어] 누적 1위는 제33회 서울가요대상 시상식 무대에서 시상합니다.',
				'[월드 트렌드 아티스트]와 [팬 초이스 오브 더 이어] 투표는 12월 31일까지 진행됩니다.',
				'서울가요대상 이달의 아티스트는 팬캐스트, 아이돌챔프, 포도알에서 투표할 수 있습니다.'
			]
		},
		{
			heading: '투표 참여 방법을 알려주세요!',
			list: [
				'서울가요대상 이달의 아티스트 투표는 1표당 100 골드 하트가 필요합니다.',
				'골드 하트는 스토어에서 동영상 재생, 충전소 미션, 구매, 이벤트 참여 등으로 획득할 수 있습니다.',
				'하루 최대 100표까지 투표할 수 있습니다.',
				'투표수는 실시간으로 합산되어 반영됩니다.'
			]
		},
		{
			heading:
				'[월드 트렌드 아티스트]와 [팬 초이스 오브 더 이어] 부문은 무슨 차이인가요?',
			list: [
				'[월드 트렌드 아티스트]는 글로벌 트렌드 리더로써 가장 많은 팬들에게 사랑받은 그룹에게 시상하는 상 입니다.',
				'[팬 초이스 오브 더 이어]는 솔로/그룹 멤버 아티스트를 대상으로, 팬들에게 가장 많은 사랑을 받은 아티스트에게 시상하는 상 입니다.'
			]
		},
		{
			heading: '누적 순위는 어떻게 계산되나요?',
			list: [
				'매월 투표 결과를 플랫폼 별 비율에 따라 점수로 환산합니다. (팬캐스트 50%, 아이돌챔프 30%, 포도알 20%)',
				'환산 점수를 기준으로 순위와 점수를 부여합니다. (1위 100점, 2위 99점, 3위 98점 …)',
				'누적 점수가 동일한 경우 환산 점수의 총합이 높은 순으로 노출됩니다.',
				'각 부문별 누적 순위 1위 아티스트는 제 33회 서울가요대상 시상식 무대에서 상을 수상합니다.'
			]
		},
		{
			heading: '※ 상기 서비스 정책은 추후 변경될 수 있습니다.'
		}
	]
};
