import {
	Box,
	Container,
	Grid,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

import images from 'assets/images';

import HowToVoteIcon from '@mui/icons-material/HowToVote';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import LoyaltyRoundedIcon from '@mui/icons-material/LoyaltyRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';

const Main = () => {
	const theme = useTheme();
	const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

	return (
		<Box component={'main'}>
			<Container maxWidth={'lg'}>
				{/* 팬캐스트 정의 */}
				<Grid container py={15} px={matchUpSm ? 0 : '20px'}>
					<Grid item xs={12} display={'flex'} justifyContent={'center'}>
						<img
							src={images.FancastMeaning}
							alt={'Fancast'}
							width={matchUpSm ? '600px' : '350px'}
							style={{ objectFit: 'contain' }}
						/>
					</Grid>
					<Grid item xs={12} display={'flex'} justifyContent={'center'} mt={6}>
						<Typography
							variant={matchUpSm ? 'h10' : 'h12'}
							color={theme.palette.black[1]}
							textAlign={'center'}
							fontWeight={600}
						>
							K-POP 팬덤이 직접 만들어가는 글로벌 플랫폼 FANCAST
						</Typography>
					</Grid>
					<Grid item xs={12} display={'flex'} justifyContent={'center'} mt={3}>
						<Typography
							variant={matchUpSm ? 'caption2' : 'caption5'}
							color={theme.palette.grey[1]}
							textAlign={'center'}
							lineHeight={1.8}
						>
							글로벌 팬덤에 대한 배려와 서비스를 기반으로, K콘텐츠 타임라인
							아카이브 플랫폼으로 성장할
							<br />
							FANCAST는 SMA, GOLDEN DISK, AAA 등 권위있는 K-POP 시상식 투표
							서비스를 비롯하여
							<br />
							차트, 펀딩, NFC 비즈니스 등 국내외 팬덤이 직접 만들어가는 다양한
							참여형 K콘텐츠 서비스를 진행 할 계획입니다.
						</Typography>
					</Grid>
				</Grid>
				{/* 팬캐스트 서비스 소개*/}
				<Grid
					container
					pt={matchUpSm ? 10 : 6}
					pb={20}
					rowSpacing={matchUpSm ? 13 : 10}
					columnSpacing={2}
					px={matchUpSm ? 0 : '20px'}
				>
					<Grid
						item
						xs={6}
						md={4}
						display={'flex'}
						flexDirection={'column'}
						alignItems={'center'}
					>
						<HowToVoteIcon
							sx={{
								fontSize: matchUpSm ? '10rem' : '8rem',
								color: theme.palette.legacy.blue
							}}
						/>
						<Typography
							variant={'h14'}
							color={theme.palette.black[1]}
							fontWeight={500}
							mt={3}
						>
							투표
						</Typography>
						<Typography
							variant={matchUpSm ? 'caption2' : 'caption3'}
							color={theme.palette.grey[1]}
							textAlign={'center'}
							lineHeight={1.7}
							mt={2}
						>
							음원시상식/음악방송 순위 투표
							<br />
							팬덤 참여/자체 기획 투표 콘텐츠
						</Typography>
					</Grid>
					<Grid
						item
						xs={6}
						md={4}
						display={'flex'}
						flexDirection={'column'}
						alignItems={'center'}
					>
						<ModeStandbyIcon
							sx={{
								fontSize: matchUpSm ? '10rem' : '8rem',
								color: theme.palette.legacy.blue
							}}
						/>
						<Typography
							variant={'h14'}
							color={theme.palette.black[1]}
							fontWeight={500}
							mt={3}
						>
							광고
						</Typography>
						<Typography
							variant={matchUpSm ? 'caption2' : 'caption3'}
							color={theme.palette.grey[1]}
							textAlign={'center'}
							lineHeight={1.7}
							mt={2}
						>
							글로벌 사용자 타겟에 맞는 광고 노출
							<br />
							다양한 펀딩 상품을 통한 온-오프라인 응원 및 후원
						</Typography>
					</Grid>
					<Grid
						item
						xs={6}
						md={4}
						display={'flex'}
						flexDirection={'column'}
						alignItems={'center'}
					>
						<QuestionAnswerRoundedIcon
							sx={{
								fontSize: matchUpSm ? '10rem' : '8rem',
								color: theme.palette.legacy.blue
							}}
						/>
						<Typography
							variant={'h14'}
							color={theme.palette.black[1]}
							fontWeight={500}
							mt={3}
						>
							커뮤니티
						</Typography>
						<Typography
							variant={matchUpSm ? 'caption2' : 'caption3'}
							color={theme.palette.grey[1]}
							textAlign={'center'}
							lineHeight={1.7}
							mt={2}
						>
							K-POP 이슈 콘텐츠 랭킹
							<br />
							이용자가 직접 생산하는 콘텐츠
						</Typography>
					</Grid>
					<Grid
						item
						xs={6}
						md={4}
						display={'flex'}
						flexDirection={'column'}
						alignItems={'center'}
					>
						<TipsAndUpdatesIcon
							sx={{
								fontSize: matchUpSm ? '10rem' : '8rem',
								color: theme.palette.legacy.blue
							}}
						/>
						<Typography
							variant={'h14'}
							color={theme.palette.black[1]}
							fontWeight={500}
							mt={3}
						>
							모먼트
						</Typography>
						<Typography
							variant={matchUpSm ? 'caption2' : 'caption3'}
							color={theme.palette.grey[1]}
							textAlign={'center'}
							lineHeight={1.7}
							mt={2}
						>
							글로벌 팬덤을 위한 K-POP 콘텐츠 큐레이션
							<br />
							주제별, 시간별 카테고리에 맞는 정보 제공
						</Typography>
					</Grid>
					<Grid
						item
						xs={6}
						md={4}
						display={'flex'}
						flexDirection={'column'}
						alignItems={'center'}
					>
						<LoyaltyRoundedIcon
							sx={{
								fontSize: matchUpSm ? '10rem' : '8rem',
								color: theme.palette.legacy.blue
							}}
						/>
						<Typography
							variant={'h14'}
							color={theme.palette.black[1]}
							fontWeight={500}
							mt={3}
						>
							커머스
						</Typography>
						<Typography
							variant={matchUpSm ? 'caption2' : 'caption3'}
							color={theme.palette.grey[1]}
							textAlign={'center'}
							lineHeight={1.7}
							mt={2}
						>
							자체 기획 굿즈
							<br />
							글로벌 팬덤의 의견을 반영한 제품 온디맨드 유통
						</Typography>
					</Grid>
					<Grid
						item
						xs={6}
						md={4}
						display={'flex'}
						flexDirection={'column'}
						alignItems={'center'}
					>
						<StarBorderRoundedIcon
							sx={{
								fontSize: matchUpSm ? '10rem' : '8rem',
								color: theme.palette.legacy.blue
							}}
						/>
						<Typography
							variant={'h14'}
							color={theme.palette.black[1]}
							fontWeight={500}
							mt={3}
						>
							슈퍼루키
						</Typography>
						<Typography
							variant={matchUpSm ? 'caption2' : 'caption3'}
							color={theme.palette.grey[1]}
							textAlign={'center'}
							lineHeight={1.7}
							mt={2}
						>
							FANCAST 기반 신인 아이돌 양성
							<br />
							해외 진출 서포트 마케팅 사업
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default Main;
