import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import images from 'assets/images';

// ----------------------------------------------------------------------

const Sma01Component = () => {
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	if (matchUpMd) {
		return (
			<Box sx={{ pt: '122px', pb: '80px' }}>
				<Container maxWidth={1200}>
					<Grid container>
						<Grid item display={'flex'} alignItems={'center'}>
							<img src={images.SmaTrophy} alt={'ic_trophy'} width={36} height={36} />
							<Typography variant={'h7'} ml={1}>
								서울가요대상 이달의 아티스트?
							</Typography>
						</Grid>
					</Grid>
					<Grid container mt={2}>
						<Grid item md={7}>
							<Typography
								variant={'caption2'}
								color={theme.palette.grey[1]}
								fontWeight={500}
							>
								서울가요대상이 주관하는 월간 시상식입니다.
								<br /> 매월 이달의 아티스트로 선정된 팀에 다양한 리워드가 주어지며,
								<br />
								2023년 4월부터 12월까지 진행하는 월간투표에서 누적 성적이 우수한
								아티스트는
								<br />
								제33회 서울가요대상 시상식에서 최종 수상하게 됩니다.
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</Box>
		);
	}

	return (
		<Box sx={{ px: '20px', pt: '40px', pb: '50px' }}>
			<Container maxWidth={1200}>
				<Grid container>
					<Grid item display={'flex'} alignItems={'flex-start'}>
						<img src={images.SmaTrophy} alt={'ic_trophy'} width={20} height={20} />
						<Typography variant={'h13'} ml={1} lineHeight={'normal'} mt={'-4px'}>
							서울가요대상
							<br />
							이달의 아티스트?
						</Typography>
					</Grid>
				</Grid>

				<Grid container mt={'12px'} pl={'28px'}>
					<Grid item>
						<Typography
							variant={'caption4'}
							color={theme.palette.grey[1]}
							fontWeight={500}
						>
							서울가요대상이 주관하는 월간 시상식입니다.
							<br /> 매월 이달의 아티스트로 선정된 팀에 다양한 리워드가 주어지며,
							<br />
							2023년 4월부터 12월까지 진행하는 월간투표에서 누적 성적이 우수한 아티스트는
							제33회 서울가요대상 시상식에서 최종 수상하게 됩니다.
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default Sma01Component;
