// ==============================|| 콘텐츠마당 ||============================== //
import ContentsMadangHorizontalDark from './contentsMadang/contents_madang_horizontal_dark.png';
import ContentsMadangHorizontalLight from './contentsMadang/contents_madang_horizontal_light.png';

// ==============================|| 팬캐스트 ||============================== //
import FancastLogoHorizontal from './legacy/fancast_logo_horizontal.png';
import FancastAppPreview from './legacy/fancast_app_preview.png';
import FancastMeaning from './legacy/fancast_meaning.png';

// ==============================|| SMA ||============================== //
import SmaLogoHorizontal from './sma/sma_logo_horizontal.png';
import SmaMainPc from './sma/sma_main_pc.png';
import SmaMainMobile from './sma/sma_main_mobile.png';
import SmaRealTrophy from './sma/sma_real_trophy.png';
import SmaBalance from './sma/sma_balance.png';
import SmaTrophy from './sma/sma_trophy.png';
import SmaSpeaker from './sma/sma_speaker.png';
import SmaZoom from './sma/sma_zoom.png';
import SmaIdolChampLogoHorizontal from './sma/sma_idol_champ_logo_horizontal.png';
import SmaPodoalLogoHorizontal from './sma/sma_podoal_logo_horizontal.png';
import SmaVoteOne from './sma/sma_vote_one.png';
import SmaVoteOneSm from './sma/sma_vote_one_sm.png';
import SmaVoteTwo from './sma/sma_vote_two.png';
import SmaVoteTwoSm from './sma/sma_vote_two_sm.png';
import SmaVoteThree from './sma/sma_vote_three.png';
import SmaVoteThreeSm from './sma/sma_vote_three_sm.png';
import SmaVoteFour from './sma/sma_vote_four.png';
import SmaVoteFourSm from './sma/sma_vote_four_sm.png';
import SmaRightDirectionWhite from './sma/sma_right_direction_white.png';
import SmaMaxVote from './sma/sma_max_vote.png';
import SmaGoldHeart from './sma/sma_gold_heart.png';
import SmaWorldTrend from './sma/sma_world_trend.png';
import SmaFanChoice from './sma/sma_fan_choice.png';
import SmaVotePeriodPc from './sma/sma_vote_period_pc.png';
import SmaVotePeriodMobile from './sma/sma_vote_period_mobile.png';
import SmaRightDirectionYellowSm from './sma/sma_right_direction_yellow_sm.png';
import SmaWorldTrendJunePc from './sma/sma_world_trend_june_pc.png';
import SmaWorldTrendJuneMobile from './sma/sma_world_trend_june_mobile.png';
import SmaFanChoiceJunePc from './sma/sma_fan_choice_june_pc.png';
import SmaFanChoiceJuneMobile from './sma/sma_fan_choice_june_mobile.png';
import SmaWorldTrendJulyPc from './sma/sma_world_trend_july_pc.png';
import SmaWorldTrendJulyMobile from './sma/sma_world_trend_july_mobile.png';
import SmaFanChoiceJulyPc from './sma/sma_fan_choice_july_pc.png';
import SmaFanChoiceJulyMobile from './sma/sma_fan_choice_july_mobile.png';
import SmaWorldTrendAugPc from './sma/sma_world_trend_aug_pc.jpg';
import SmaWorldTrendAugMobile from './sma/sma_world_trend_aug_mobile.jpg';
import SmaFanChoiceAugPc from './sma/sma_fan_choice_aug_pc.jpg';
import SmaFanChoiceAugMobile from './sma/sma_fan_choice_aug_mobile.jpg';
import SmaWorldTrendSepPc from 'assets/images/sma/sma_world_trend_sep_pc.jpg';
import SmaWorldTrendSepMobile from 'assets/images/sma/sma_world_trend_sep_mobile.jpg';
import SmaFanChoiceSepPc from 'assets/images/sma/sma_fan_choice_sep_pc.jpg';
import SmaFanChoiceSepMobile from 'assets/images/sma/sma_fan_choice_sep_mobile.jpg';
import SmaWorldTrendOctPc from 'assets/images/sma/sma_world_trend_oct_pc.jpg';
import SmaWorldTrendOctMobile from 'assets/images/sma/sma_world_trend_oct_mobile.jpg';
import SmaFanChoiceOctPc from 'assets/images/sma/sma_fan_choice_oct_pc.jpg';
import SmaFanChoiceOctMobile from 'assets/images/sma/sma_fan_choice_oct_mobile.jpg';
import SmaWorldTrendNovPc from 'assets/images/sma/sma_world_trend_nov_pc.png';
import SmaWorldTrendNovMobile from 'assets/images/sma/sma_world_trend_nov_mobile.png';
import SmaFanChoiceNovPc from 'assets/images/sma/sma_fan_choice_nov_pc.png';
import SmaFanChoiceNovMobile from 'assets/images/sma/sma_fan_choice_nov_mobile.png';

// ==============================|| 드림콘서트 ||============================== //
// 드림콘서트 29th 로고
import DreamConcertLogo29th from './dreamConcert/dream_concert_logo_29th.png';
import DreamConcertLogo29thLight from './dreamConcert/dream_concert_logo_29th_light.png';

// 드림루키 메인
import DreamRookieMainPc from './dreamConcert/dreamRookie/dream_rookie_main_pc.jpg';
import DreamRookieMainMobile from './dreamConcert/dreamRookie/dream_rookie_main_mobile.jpg';
import DreamRookiePrizePc from './dreamConcert/dreamRookie/dream_rookie_prize_pc.jpg';
import DreamRookiePrizeMobile from './dreamConcert/dreamRookie/dream_rookie_prize_mobile.jpg';

// 협력사 로고
import SupportKepa from './dreamConcert/dreamRookie/support/kepa.png';
import SupportKto from './dreamConcert/dreamRookie/support/kto.png';
import SupportMcst from './dreamConcert/dreamRookie/support/mcst.png';
import SupportViaje from './dreamConcert/dreamRookie/support/viaje.png';
import SupportHd from './dreamConcert/dreamRookie/support/hd.png';
import SupportComwithus from './dreamConcert/dreamRookie/support/comwithus.png';
import SupportLavata from './dreamConcert/dreamRookie/support/lavata.png';
import SupportStoneb from './dreamConcert/dreamRookie/support/stoneb.png';

// 드림루키 후보자
import RookieNineI from './dreamConcert/dreamRookie/artist/1stYear/nine_i.jpg';
import RookieLapillus from './dreamConcert/dreamRookie/artist/1stYear/lapillus.jpg';
import RookieFiftyFifty from './dreamConcert/dreamRookie/artist/1stYear/fifty_fifty.jpg';
import Rookie8Turn from './dreamConcert/dreamRookie/artist/1stYear/eight_turn.jpg';
import RookieClassy from './dreamConcert/dreamRookie/artist/1stYear/classy.jpg';
import RookieMirae from './dreamConcert/dreamRookie/artist/2stYear/mirae.jpg';
import RookieBillie from './dreamConcert/dreamRookie/artist/2stYear/billie.jpg';
import RookieJustB from './dreamConcert/dreamRookie/artist/2stYear/just_b.jpg';
import RookieTriBe from './dreamConcert/dreamRookie/artist/2stYear/tri_be.jpg';
import RookiePurpleKiss from './dreamConcert/dreamRookie/artist/2stYear/purple_kiss.jpg';
import RookiePixy from './dreamConcert/dreamRookie/artist/2stYear/pixy.png';
import RookieBlitzers from './dreamConcert/dreamRookie/artist/2stYear/blitzers.jpg';
import RookieYeahShine from './dreamConcert/dreamRookie/artist/2stYear/yeah_shine.jpg';

// ==============================|| 아이콘 ||============================== //
// 홈페이지
import Homepage from './icon/homepage.png';

// 인스타그램
import Instagram from './icon/instagram.png';

// 틱톡
import Tiktok from './icon/tiktok.png';

// 트위터
import Twitter from './icon/twitter.png';

// 유튜브
import Youtube from './icon/youtube.png';

// 페이스북
import Facebook from './icon/facebook.png';

import HamburgerBlack from './icon/hamburger_black.png';

import CancelBlack from './icon/cancel_black.png';

import CancelWhite from './icon/cancel_white.png';

export default {
	// 콘텐츠마당
	ContentsMadangHorizontalDark,
	ContentsMadangHorizontalLight,
	// 팬캐스트
	FancastLogoHorizontal,
	FancastAppPreview,
	FancastMeaning,
	// SMA
	SmaLogoHorizontal,
	SmaMainPc,
	SmaMainMobile,
	SmaRealTrophy,
	SmaBalance,
	SmaTrophy,
	SmaSpeaker,
	SmaZoom,
	SmaIdolChampLogoHorizontal,
	SmaPodoalLogoHorizontal,
	SmaVoteOne,
	SmaVoteOneSm,
	SmaVoteTwo,
	SmaVoteTwoSm,
	SmaVoteThree,
	SmaVoteThreeSm,
	SmaVoteFour,
	SmaVoteFourSm,
	SmaRightDirectionWhite,
	SmaMaxVote,
	SmaGoldHeart,
	SmaWorldTrend,
	SmaFanChoice,
	SmaVotePeriodPc,
	SmaVotePeriodMobile,
	SmaRightDirectionYellowSm,
	SmaWorldTrendJunePc,
	SmaWorldTrendJuneMobile,
	SmaFanChoiceJunePc,
	SmaFanChoiceJuneMobile,
	SmaWorldTrendJulyPc,
	SmaWorldTrendJulyMobile,
	SmaFanChoiceJulyPc,
	SmaFanChoiceJulyMobile,
	SmaWorldTrendAugPc,
	SmaWorldTrendAugMobile,
	SmaFanChoiceAugPc,
	SmaFanChoiceAugMobile,
	SmaWorldTrendSepPc,
	SmaWorldTrendSepMobile,
	SmaFanChoiceSepPc,
	SmaFanChoiceSepMobile,
	SmaWorldTrendOctPc,
	SmaWorldTrendOctMobile,
	SmaFanChoiceOctPc,
	SmaFanChoiceOctMobile,
	SmaWorldTrendNovPc,
	SmaWorldTrendNovMobile,
	SmaFanChoiceNovPc,
	SmaFanChoiceNovMobile,
	// 드림콘서트 29th 로고
	DreamConcertLogo29th,
	DreamConcertLogo29thLight,
	// 드림루키
	DreamRookieMainPc,
	DreamRookieMainMobile,
	DreamRookiePrizePc,
	DreamRookiePrizeMobile,
	// 협력사 로고
	SupportKepa,
	SupportKto,
	SupportMcst,
	SupportViaje,
	SupportHd,
	SupportComwithus,
	SupportLavata,
	SupportStoneb,
	// 드림루키 후보자
	RookieNineI,
	RookieLapillus,
	RookieFiftyFifty,
	Rookie8Turn,
	RookieClassy,
	RookieMirae,
	RookieBillie,
	RookieJustB,
	RookieTriBe,
	RookiePurpleKiss,
	RookiePixy,
	RookieBlitzers,
	RookieYeahShine,
	// 홈페이지
	Homepage,
	// 인스타그램
	Instagram,
	// 틱톡
	Tiktok,
	// 트위터
	Twitter,
	// 유튜브
	Youtube,
	// 페이스북
	Facebook,
	HamburgerBlack,
	CancelBlack,
	CancelWhite
};
