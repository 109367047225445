import {
	Box,
	Container,
	Grid,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

import images from 'assets/images';

const Footer = () => {
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<Box
			component={'footer'}
			bgcolor={theme.palette.purple[1]}
			pt={matchUpMd ? '60px' : '40px'}
			pb={matchUpMd ? '40px' : '80px'}
			px={matchUpMd ? 0 : '20px'}
		>
			<Container maxWidth={'lg'}>
				<Grid container direction={matchUpMd ? 'row' : 'column-reverse'}>
					<Grid
						item
						container
						xs={12}
						md={5}
						direction={'column'}
						justifyContent={'flex-end'}
						mt={matchUpMd ? 0 : '60px'}
					>
						<img
							src={images.DreamConcertLogo29thLight}
							alt={'드림콘서트_로고'}
							width={matchUpMd ? '211px' : '148px'}
							height={matchUpMd ? '40x' : '28px'}
						/>
						<Typography mt={'12px'} color={theme.palette.purple[3]}>
							Copyright © 2022 Contentsmadang , Inc. All Rights Reserved
						</Typography>
					</Grid>
					<Grid
						item
						container
						xs={12}
						md={7}
						direction={'row'}
						alignItems={'center'}
						justifyItems={'flex-end'}
						justifyContent={'flex-end'}
						rowSpacing={2}
					>
						<Grid item xs={2.5} md={3}>
							<Typography variant={'caption5'} color={theme.palette.purple[4]}>
								공동주최
							</Typography>
						</Grid>
						<Grid item xs={9.5} md={9}>
							<img
								src={images.SupportKepa}
								alt={'한국연예제작자협회'}
								width={matchUpMd ? '196px' : '147px'}
								height={matchUpMd ? '32px' : '24px'}
							/>
							<Box display={'inline-block'} width={'15px'} />
							<img
								src={images.SupportKto}
								alt={'한국관광공사'}
								width={matchUpMd ? '96px' : '72px'}
								height={matchUpMd ? '32px' : '24px'}
							/>
						</Grid>
						<Grid item xs={2.5} md={3}>
							<Typography variant={'caption5'} color={theme.palette.purple[4]}>
								후원
							</Typography>
						</Grid>
						<Grid item xs={9.5} md={9}>
							<img
								src={images.SupportHd}
								alt={'HD현대'}
								width={matchUpMd ? '102px' : '76px'}
								height={matchUpMd ? '32px' : '24px'}
							/>
						</Grid>
						<Grid item xs={2.5} md={3}>
							<Typography variant={'caption5'} color={theme.palette.purple[4]}>
								특별후원
							</Typography>
						</Grid>
						<Grid item xs={9.5} md={9}>
							<img
								src={images.SupportMcst}
								alt={'문화체육관광부'}
								width={matchUpMd ? '160px' : '120px'}
								height={matchUpMd ? '32px' : '24px'}
							/>
						</Grid>
						<Grid item xs={2.5} md={3}>
							<Typography variant={'caption5'} color={theme.palette.purple[4]}>
								투표주관
							</Typography>
						</Grid>
						<Grid item xs={9.5} md={9}>
							<img
								src={images.ContentsMadangHorizontalLight}
								alt={'콘텐츠마당'}
								width={matchUpMd ? '105px' : '79px'}
								height={matchUpMd ? '32px' : '24px'}
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<Typography variant={'caption5'} color={theme.palette.purple[4]}>
								온라인 실시간 생중계
							</Typography>
						</Grid>
						<Grid item xs={12} md={9} mt={matchUpMd ? 0 : '-4px'}>
							<img
								src={images.SupportViaje}
								alt={'viaje'}
								width={matchUpMd ? '112px' : '84px'}
								height={matchUpMd ? '32px' : '24px'}
							/>
							<Box display={'inline-block'} width={'20px'} />
							<img
								src={images.SupportComwithus}
								alt={'comwithus'}
								width={matchUpMd ? '126px' : '95px'}
								height={matchUpMd ? '32px' : '24px'}
							/>
							<Box display={'inline-block'} width={'20px'} />
							<img
								src={images.SupportLavata}
								alt={'lavata'}
								width={matchUpMd ? '114px' : '85px'}
								height={matchUpMd ? '32px' : '24px'}
							/>
							<Box display={'inline-block'} width={'20px'} />
							<img
								src={images.SupportStoneb}
								alt={'stone.b'}
								width={matchUpMd ? '62px' : '46px'}
								height={matchUpMd ? '32px' : '24px'}
								style={{ marginTop: matchUpMd ? 0 : '8px' }}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default Footer;
