import {
	Box,
	Container,
	Grid,
	Stack,
	styled,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

import images from 'assets/images';

import { CALCULATION } from 'views/sma/constants';

// ----------------------------------------------------------------------

const StyledCalcBox = styled(Box)(({ borderColor, backgroundColor, matchUpMd }) => ({
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: borderColor,
	backgroundColor: backgroundColor,
	display: 'flex',
	flexDirection: 'column',
	padding: matchUpMd ? '30px 36px 14px 36px' : '18px 20px 11px 20px',
	height: matchUpMd ? 220 : 157,
	borderRadius: matchUpMd ? 24 : 20
}));

const StyledPlatFormImageBox = styled(Box)(() => ({
	display: 'flex',
	height: '100%',
	alignItems: 'center',
	justifyContent: 'center'
}));

const StyledRatioBackgroundBar = styled(Box)(({ theme, id, matchUpMd }) => ({
	position: 'relative',
	marginTop: matchUpMd ? '14px' : '11px',
	display: 'flex',
	height: 2,
	backgroundColor: id === 'fancast' ? theme.palette.grey[4] : theme.palette.grey[6]
}));

const StyledRatioBar = styled(Box)(({ theme, item, matchUpSm }) => ({
	position: 'absolute',
	top: 0,
	right: item.position,
	width: `${item.ratio}%`,
	height: 2,
	backgroundColor:
		item.id === 'fancast' ? theme.palette.yellow[1] : theme.palette.yellow[2]
}));

// ----------------------------------------------------------------------

const Sma02Component = () => {
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	if (matchUpMd) {
		return (
			<Box
				sx={{
					pt: '80px',
					pb: '40px'
				}}
			>
				<Container maxWidth={1200}>
					<Grid container>
						<Grid item display={'flex'} alignItems={'center'}>
							<img src={images.SmaBalance} alt={'ic_balance'} width={36} height={36} />
							<Typography variant={'h7'} ml={1}>
								1위 선정 방식
							</Typography>
						</Grid>
					</Grid>

					<Grid container mt={2}>
						<Grid item md={'auto'}>
							<Typography
								variant={'caption2'}
								color={theme.palette.grey[1]}
								fontWeight={500}
							>
								서울가요대상 이달의 아티스트는 <b>100% 팬 투표</b>로 선정됩니다.
								<br />
								국내 최대 팬덤 플랫폼 3사에서 투표가 진행되며, 플랫폼별 투표수를
								반영비율에 따라 점수로 환산하여
								<br />
								점수가 가장 높은 아티스트가 당월 이달의 아티스트로서 영예를 누리게 됩니다.
							</Typography>
						</Grid>
					</Grid>

					<Grid container pt={'28px'} columnSpacing={3} rowSpacing={'12px'}>
						{CALCULATION.map((item) => (
							<Grid item xs={12} key={`calculation-${item.id}`} {...item.grid}>
								<StyledCalcBox
									matchUpMd={matchUpMd}
									borderColor={item.borderColor}
									backgroundColor={item.backgroundColor}
								>
									<Stack
										flexDirection={'row'}
										alignItems={'baseline'}
										justifyContent={'space-between'}
									>
										<div>
											<Typography
												variant={'caption1'}
												fontWeight={500}
												color={theme.palette.black[1]}
											>
												{item.title}
											</Typography>
										</div>
										<Stack flexDirection={'row'} alignItems={'baseline'}>
											<Typography
												variant={'caption7'}
												mr={'6px'}
												color={theme.palette.grey[2]}
											>
												반영비율
											</Typography>
											<Typography
												variant={'h10'}
												fontWeight={700}
												color={theme.palette.black[1]}
											>
												{item.ratio}
											</Typography>
											<Typography
												variant={'caption1'}
												fontWeight={700}
												color={theme.palette.black[1]}
											>
												%
											</Typography>
										</Stack>
									</Stack>

									<StyledRatioBackgroundBar
										id={item.id}
										ratio={item.ratio}
										matchUpMd={matchUpMd}
									>
										<StyledRatioBar item={item} matchUpSm={matchUpMd} />
									</StyledRatioBackgroundBar>

									<StyledPlatFormImageBox>
										<img
											src={item.image}
											alt={`sma_platForm_${item.id}`}
											{...item.imageSize.md}
										/>
									</StyledPlatFormImageBox>
								</StyledCalcBox>
							</Grid>
						))}
					</Grid>
				</Container>
			</Box>
		);
	}

	return (
		<Box
			sx={{
				pt: '50px',
				pb: '30px',
				px: '20px'
			}}
		>
			<Container maxWidth={1200}>
				<Grid container>
					<Grid item display={'flex'} alignItems={'center'}>
						<img src={images.SmaBalance} alt={'ic_balance'} width={20} height={20} />
						<Typography variant={'h13'} ml={1}>
							1위 선정 방식
						</Typography>
					</Grid>
				</Grid>

				<Grid container mt={2} pl={'28px'}>
					<Grid item md={'auto'}>
						<Typography
							variant={'caption4'}
							color={theme.palette.grey[1]}
							fontWeight={500}
						>
							서울가요대상 이달의 아티스트는 <b>100% 팬 투표</b>로 선정됩니다.
							<br />
							국내 최대 팬덤 플랫폼 3사에서 투표가 진행되며, 플랫폼별 투표수를 반영비율에
							따라 점수로 환산하여
							<br />
							점수가 가장 높은 아티스트가 당월 이달의 아티스트로서 영예를 누리게 됩니다.
						</Typography>
					</Grid>
				</Grid>

				<Grid container pt={'20px'} pl={'28px'} columnSpacing={3} rowSpacing={'12px'}>
					{CALCULATION.map((item) => (
						<Grid item xs={12} key={`calculation-${item.id}`} {...item.grid}>
							<StyledCalcBox
								matchUpMd={matchUpMd}
								borderColor={item.borderColor}
								backgroundColor={item.backgroundColor}
							>
								<Stack
									flexDirection={'row'}
									alignItems={'baseline'}
									justifyContent={'space-between'}
								>
									<div>
										<Typography
											variant={'caption3'}
											fontWeight={500}
											color={theme.palette.black[1]}
										>
											{item.title}
										</Typography>
									</div>
									<Stack flexDirection={'row'} alignItems={'baseline'}>
										<Typography
											variant={'caption7'}
											mr={'6px'}
											color={theme.palette.grey[2]}
										>
											반영비율
										</Typography>
										<Typography
											variant={'h12'}
											fontWeight={700}
											color={theme.palette.black[1]}
										>
											{item.ratio}
										</Typography>
										<Typography
											variant={'caption3'}
											fontWeight={700}
											color={theme.palette.black[1]}
										>
											%
										</Typography>
									</Stack>
								</Stack>

								<StyledRatioBackgroundBar
									id={item.id}
									ratio={item.ratio}
									matchUpMd={matchUpMd}
								>
									<StyledRatioBar item={item} matchUpMd={matchUpMd} />
								</StyledRatioBackgroundBar>

								<StyledPlatFormImageBox>
									<img
										src={item.image}
										alt={`sma_platForm_${item.id}`}
										{...item.imageSize.sm}
									/>
								</StyledPlatFormImageBox>
							</StyledCalcBox>
						</Grid>
					))}
				</Grid>
			</Container>
		</Box>
	);
};

export default Sma02Component;
