import { useState } from 'react';
import {
	Box,
	ButtonBase,
	Container,
	Divider,
	Drawer,
	Grid,
	Link,
	styled,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import * as ReactScroll from 'react-scroll';
import { useNavigate } from 'react-router-dom';

import images from 'assets/images';

import { MENUS } from 'views/sma/constants';
import { APP_FANCAST_VOTE_MAIN_URL } from 'constants/common';

// ----------------------------------------------------------------------

const StyledHeaderSticky = styled(Box)(({ theme }) => ({
	zIndex: 1500,
	position: 'sticky',
	top: 0,
	backgroundColor: 'white',
	borderBottomColor: theme.palette.grey[3],
	borderBottomWidth: 1,
	borderBottomStyle: 'solid'
}));

const StyledHeaderContainer = styled(Container)(({ matchUpSm }) => ({
	height: matchUpSm ? 74 : 56,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between'
}));

const SmaLogo = styled('img')(({ matchUpSm }) => ({
	width: matchUpSm ? 250 : 182,
	height: matchUpSm ? 30 : 22
}));

const StyledVoteButton = styled(ButtonBase)(({ theme, matchUpMd }) => ({
	backgroundColor: 'black',
	color: 'white',
	...(matchUpMd && {
		marginLeft: 28,
		fontSize: 16,
		fontWeight: 600,
		borderRadius: 26,
		padding: '12px 24px'
	}),
	...(!matchUpMd && {
		width: '100%',
		fontSize: 17,
		fontWeight: 600,
		borderRadius: 30,
		padding: 20
	})
}));

const StyledMobileNavContainer = styled(Box)({
	display: 'flex',
	height: '100%',
	flexDirection: 'column',
	justifyContent: 'flex-end',
	padding: '0 20px'
});

const StyledMobileNav = styled('div')({
	padding: '40px 0px',
	height: '100%'
});

const StyledDivider = styled(Divider)(({ theme }) => ({
	borderColor: theme.palette.grey[4],
	margin: '20px 0'
}));

// ----------------------------------------------------------------------

const Header = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
	const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

	const scroller = ReactScroll.scroller;

	const [currentId, setCurrentId] = useState('');

	const [isOpenMobileNav, setIsOpenMobileNav] = useState(false);

	// PC 메뉴 선택
	const handleClickPCMenu = async (menu) => {
		await navigate(menu.url);
		await scroller.scrollTo(menu.id, {
			spy: true,
			smooth: true,
			offset: -40
		});

		setCurrentId(menu.id);
	};

	// Mobile 메뉴 선택
	const handleClickMenu = async (menu) => {
		if (isOpenMobileNav) {
			setIsOpenMobileNav(!isOpenMobileNav);
		}

		await navigate(menu.url);
		await scroller.scrollTo(menu.id, {
			spy: true,
			smooth: true,
			offset: -40
		});
	};

	// 모바일 메뉴창 열기/닫기
	const handleClickMobileNav = () => {
		setIsOpenMobileNav(!isOpenMobileNav);
	};

	// 투표 메인 화면으로 이동
	const handleMoveVote = () => {
		window.open(APP_FANCAST_VOTE_MAIN_URL);
		if (isOpenMobileNav) {
			setIsOpenMobileNav(!setIsOpenMobileNav);
		}
	};

	// ----------------------------------------------------------------------

	// PC 버전 Header
	if (matchUpMd) {
		return (
			<StyledHeaderSticky component={'header'}>
				<StyledHeaderContainer maxWidth={1200} matchUpSm={matchUpSm}>
					<Grid container>
						<Grid item>
							<SmaLogo
								matchUpSm={matchUpSm}
								src={images.SmaLogoHorizontal}
								alt={'sma_logo_horizontal'}
							/>
						</Grid>
						<Grid
							item
							container
							xs
							display={'flex'}
							alignItems={'center'}
							justifyContent={'flex-end'}
						>
							{MENUS.map((menu, index) => (
								<Grid item key={`header-menu-${menu.id}`} ml={4}>
									<ReactScroll.Link
										to={menu.id}
										spy={true}
										smooth={true}
										offset={-50}
										onClick={() => handleClickPCMenu(menu)}
									>
										<Typography
											variant={'caption3'}
											color={theme.palette.grey[1]}
											fontWeight={currentId === menu.id ? 700 : 500}
											sx={{ cursor: 'pointer', display: 'block' }}
										>
											{menu.text}
										</Typography>
									</ReactScroll.Link>
								</Grid>
							))}
							<StyledVoteButton
								disableRipple
								matchUpMd={matchUpMd}
								onClick={handleMoveVote}
							>
								투표하러 가기
							</StyledVoteButton>
						</Grid>
					</Grid>
				</StyledHeaderContainer>
			</StyledHeaderSticky>
		);
	}

	// Mobile 버전 Header
	return (
		<>
			<StyledHeaderSticky component={'header'}>
				<StyledHeaderContainer maxWidth={1200} matchUpSm={matchUpSm}>
					<Grid container px={'20px'}>
						<Grid item>
							<SmaLogo
								matchUpSm={matchUpSm}
								src={images.SmaLogoHorizontal}
								alt={'sma_logo_horizontal'}
							/>
						</Grid>
						<Grid
							item
							xs
							display={'flex'}
							alignItems={'center'}
							justifyContent={'flex-end'}
						>
							<ButtonBase disableRipple disableTouchRipple onClick={handleClickMobileNav}>
								<img
									src={isOpenMobileNav ? images.CancelBlack : images.HamburgerBlack}
									alt={'hamburger_menu'}
									width={28}
									height={28}
								/>
							</ButtonBase>
						</Grid>
					</Grid>
				</StyledHeaderContainer>
			</StyledHeaderSticky>

			<Drawer
				anchor={'right'}
				open={isOpenMobileNav}
				onClose={handleClickMobileNav}
				PaperProps={{
					sx: {
						pt: '56px',
						width: '100%'
					}
				}}
			>
				<StyledMobileNavContainer>
					<StyledMobileNav>
						{MENUS.map((menu, index) => (
							<div key={`header-menu-${menu.id}`}>
								<Link
									to={menu.id}
									spy={true}
									smooth={true}
									offset={-50}
									underline={'none'}
									onClick={() => handleClickMenu(menu)}
								>
									<Typography
										variant={'caption2'}
										fontWeight={500}
										color={theme.palette.black[1]}
										sx={{ cursor: 'pointer', display: 'block' }}
									>
										{menu.text}
									</Typography>
								</Link>
								{MENUS.length !== index + 1 && <StyledDivider />}
							</div>
						))}
					</StyledMobileNav>
				</StyledMobileNavContainer>
				<Box sx={{ p: '28px 20px' }}>
					<StyledVoteButton disableRipple matchUpMd={matchUpMd} onClick={handleMoveVote}>
						투표하러 가기
					</StyledVoteButton>
				</Box>
			</Drawer>
		</>
	);
};

export default Header;
